import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
//import { ConnectionService } from '@cn/mobile-core';
import { UserService } from '@cn/mobile-core';
import { ConnectionStatus } from "./connection-status"

@Injectable()
export class AuthorizationGuard implements CanActivate {
  private connection: any;
  constructor(
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    
    this.connection = this.getConnection();
    if (!this.connection.isConnected) {
      return true;
    } else {
      const userService = new UserService()
      if (!!userService.getCurrentUser()) {
        return true;
      }
      this.router.navigateByUrl('/auth');
      return false;
    }
  }
  getConnection(): ConnectionStatus {
    let connect: ConnectionStatus;
    connect = { isConnected: navigator.onLine, type: "wifi" };
    return connect;
  }
}
