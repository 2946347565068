import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitcherComponent } from '@src/app/shared/language-switcher/language-switcher.component';
import { LanguageService } from '@src/app/shared/language.service';
//import { LocalizationModule } from 'mobile-core/src/lib/localization/localization.module';
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LanguageSwitcherCNOneComponent } from './language-switcher-cnOne/language-switcher-cnone.component';
import { SelectComponent } from './fieldComponents/select/select.component';
import { DynamicFieldDirective } from './fieldComponents/dynamicfield/dynamic-field.directive';
import { InputComponent } from './fieldComponents/input/input.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [LanguageSwitcherComponent, LanguageSwitcherCNOneComponent, SelectComponent, DynamicFieldDirective, InputComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  entryComponents: [
    SelectComponent,
    InputComponent
  ],
  exports: [LanguageSwitcherComponent,LanguageSwitcherCNOneComponent, MatDialogModule, SelectComponent, DynamicFieldDirective, InputComponent],
  providers: [LanguageService],
})
export class SharedModule {}
