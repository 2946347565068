import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectComponent } from '../select/select.component';
import { DataService, UserService, where } from '@cn/mobile-core';
import { InputComponent } from '../input/input.component';

const componentMapper = {
  input: InputComponent,
  select: SelectComponent,
};
@Directive({
  selector: '[dynamicField]',
})
export class DynamicFieldDirective {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() modeOperation: any;
  @Input() index: any;
  @Input() formType: any;
  componentRef: any;
  inputType: any;
  dataService = new DataService();
  userService = new UserService();
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  async ngOnInit() {
    if (this.userService.getCurrentUser()) {
      const user = this.userService.getCurrentUser();
      const queryConstraint: any = [where('uid', '==', user.uid)];

      const userContext = this.dataService
        .collection('UserContext')
        .find(queryConstraint);
      let userDetails: any;
      if (Object.keys(userContext).length !== 0) {
        (await userContext).forEach((doc) => {
          userDetails = doc.data();
        });
      }
      //  if(this.field.loggedInWith==undefined){
      //    this.field = this.field.data();
      //  }
      if (
        userDetails.idTokenWithContext.loggedInWith === 'aad' &&
        this.field &&
        this.field.loggedInWith.aad.display
      ) {
        const factory = this.resolver.resolveComponentFactory(
          componentMapper[this.field.loggedInWith.aad.inputType]
        );
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.group = this.group;
        this.componentRef.instance.formType = this.formType;
      } else if (
        userDetails.idTokenWithContext.loggedInWith === 'isam' &&
        this.field &&
        this.field.loggedInWith.isam.display
      ) {
        
        const factory = this.resolver.resolveComponentFactory(
          componentMapper[this.field.loggedInWith.isam.inputType]
        );
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.group = this.group;
        this.componentRef.instance.formType = this.formType;
      } else {
        return;
      }
    }
  }
}
