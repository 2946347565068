
import { Component, NgZone, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "@cn/mobile-core";
import { UserService, where } from "@cn/mobile-core";

@Component({
  selector: 'app-input',
  template: `<div>
      <label
        >{{ field.label[language] }}
        <span
          *ngIf="
            field.validations[0].name == 'required' &&
            field.validations[0].validator
          "
          >*</span
        ></label
      >
    </div>
    <mat-form-field [formGroup]="group" floatLabel="never">
      <input
        matInput
        [formControlName]="field.name"
        placeholder="{{ field.placeholder[language] }}"
        [readonly]="readonly"
        [class.readonly_input]="readonly"
      />
      <ng-container
        *ngFor="let validation of field.validations"
        ngProjectAs="mat-error"
      >
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">
          <span class="icon-notification2" style="color: brown;"> </span>
          {{ validation.message[language] }}
        </mat-error>
      </ng-container>
    </mat-form-field> `,
  styles: [],
})
export class InputComponent implements OnInit {
  field: any;
  group: FormGroup;
  collection: any;
  options: any = [];
  readonly = false;
  loggedInWith = '';
  language: string;
  private userService = new UserService()
  private dataService = new DataService()
  constructor(
    
    private zone: NgZone,
    
  ) {}
  async ngOnInit() {
    this.language = JSON.parse(sessionStorage.getItem('language')) || 'en';
    if(this.userService.getCurrentUser()){
      
      const user = this.userService.getCurrentUser();
      const queryConstraint: any = [where('uid', '==', user.uid)];  
      const userContext =  this.dataService.collection("UserContext").find(queryConstraint);
      console.log('userContext: ',userContext)
      let userDetails: any;
      if(Object.keys(userContext).length !== 0) {
      
        (await (userContext)).forEach((doc) => {
          userDetails = doc.data();
        console.log('userDetails: ',userDetails)
        })
      
        this.loggedInWith = userDetails.idTokenWithContext.loggedInWith;
        if (
          userDetails.idTokenWithContext.loggedInWith === 'aad' &&
          this.field &&
          this.field.loggedInWith.aad.display
        ) {
          if (this.field.modeOfOperation === 'editSubscription') {
            if (!this.field.loggedInWith.aad.isEditable) {
              this.readonly = true;
            }
            if (this.field.loggedInWith.aad.selectedValue != '') {
              this.fetchDatawithAad();
            } else {
              if (!this.field.loggedInWith.aad.isEditable) {
                this.group.get(this.field.name).setValue('----');
              } else {
                this.group.get(this.field.name).setValue('');
              }
            }
          }
        }
        if (
          userDetails.idTokenWithContext.loggedInWith === 'isam' &&
          this.field &&
          this.field.loggedInWith.isam.display
        ) {
          if (this.field.modeOfOperation === 'editSubscription') {
            if (!this.field.loggedInWith.isam.isEditable) {
              this.readonly = true;
            }
            if (this.field.loggedInWith.isam.selectedValue != '') {
              this.fetchDatawithIsam();
            } else {
              if (!this.field.loggedInWith.isam.isEditable) {
                this.group.get(this.field.name).setValue('----');
              } else {
                this.group.get(this.field.name).setValue('');
              }
            }
          }
        }
      }
    }
  }
  async fetchDatawithAad() {
    if (this.field.toFetchFromCollection !== '') {
      let query : any;
      this.collection = this.dataService.collection(
        this.field.toFetchFromCollection
      );
      query = [where('code','==', this.field.loggedInWith.aad.selectedValue)];
      const collectionVal = this.collection.find(query);
      if(Object.keys(collectionVal).length!==0){
        let data: any;
        (await (collectionVal)).forEach((doc) => {
          data = doc.data();
        console.log('collectionVal: ',collectionVal)
        })
      
      this.zone.run(() => {
          this.group
            .get(this.field.name)
            .setValue(data[0][this.field.valueToShowinDropdown]);
        });
      }
    } else {
      this.group
        .get(this.field.name)
        .setValue(this.field.loggedInWith.aad.selectedValue);
    }
  }
 async  fetchDatawithIsam() {
    if (this.field.toFetchFromCollection !== '') {
      
      let query = [where('code','==', this.field.loggedInWith.isam.selectedValue)];
      this.collection = this.dataService.collection(
        this.field.toFetchFromCollection
      );
      const collectionVal = this.collection.find(query);
      if(Object.keys(collectionVal).length!==0){
        let data: any;
        (await (collectionVal)).forEach((doc) => {
          data = doc.data();
        console.log('collectionVal: ',collectionVal)
        })
        this.zone.run(() => {
          this.group
            .get(this.field.name)
            .setValue(data[0][this.field.valueToShowinDropdown]);
        });
      }
    } else {
      this.group
        .get(this.field.name)
        .setValue(this.field.loggedInWith.isam.selectedValue);
    }
  }
}