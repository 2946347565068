import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor() { }

  private mysubscriptionSubject = new Subject<any>();
  private subscriptionCountsSubject = new Subject<any>();
  private managedCountsSubject = new Subject<any>();
  private filterSubject = new Subject<any>();
  private filterCategory = new Subject<any>();
  private subscriptionsOnCategory = new Subject<any>();
  private customAddSubAttributeData = new Subject<any>();
  private customEditSubAttributeData = new Subject<any>();
  private customFilterSubAttributeData = new Subject<any>();
  private selectedNotificationType = new Subject<any>();
  private isIntermodalNotification =  new BehaviorSubject('N');


  _homeFilterCriteria = {};
  filterCriteriaDataToHome = new Subject<any>();


  sendcustomAddSubAttributeData(message: any) {
    this.customAddSubAttributeData.next({ text: message });
  }

  clearcustomAddSubAttributeData() {
    this.customAddSubAttributeData.next();
  }

  getcustomAddSubAttributeData(): Observable<any> {
    return this.customAddSubAttributeData.asObservable();
  }
  private langSwitcher = new Subject<any>();

  sendSubscriptionData(message: boolean) {
    this.mysubscriptionSubject.next({ text: message });
  }

  clearSubscriptionData() {
    this.mysubscriptionSubject.next();
  }

  getSubscriptionData(): Observable<any> {
    return this.mysubscriptionSubject.asObservable();
  }


  sendDeleteSubscriptionData(message: boolean) {
    this.mysubscriptionSubject.next({ text: message });
  }

  clearDeleteSubscriptionData() {
    this.mysubscriptionSubject.next();
  }

  getDeleteSubscriptionData(): Observable<any> {
    return this.mysubscriptionSubject.asObservable();
  }

  sendDeleteTemplateData(message: boolean) {
    this.mysubscriptionSubject.next({ text: message });
  }

  clearDeleteTemplateData() {
    this.mysubscriptionSubject.next();
  }

  getDeleteTemplateData(): Observable<any> {
    return this.mysubscriptionSubject.asObservable();
  }

  
  sendAddTemplateData(message: boolean) {
    this.mysubscriptionSubject.next({ text: message });
  }

  clearAddTemplateData() {
    this.mysubscriptionSubject.next();
  }

  getAddTemplateData(): Observable<any> {
    return this.mysubscriptionSubject.asObservable();
  }

  sendFilterData(message: string) {
    this.filterSubject.next({ text: message });
  }

  clearFilterData() {
    this.filterSubject.next();
  }

  getFilterData(): Observable<any> {
    return this.filterSubject.asObservable();
  }

  sendFilterCategoryData(message: string) {
    this.filterCategory.next({ text: message });
  }

  clearFilterCategoryData() {
    this.filterCategory.next();
  }

  getFilterCategoryData(): Observable<any> {
    return this.filterCategory.asObservable();
  }

  sendSubscriptionsCategoryData(message: string) {
    this.subscriptionsOnCategory.next({ text: message });
  }

  clearSubscriptionsCategoryData() {
    this.subscriptionsOnCategory.next();
  }

  getSubscriptionsCategoryData(): Observable<any> {
    return this.subscriptionsOnCategory.asObservable();
  }


  sendLangSwitcherData(message: string) {
    this.langSwitcher.next({ text: message });
  }

  clearLangSwitcherData() {
    this.langSwitcher.next();
  }

  getLangSwitcherData(): Observable<any> {
    return this.langSwitcher.asObservable();
  }


  sendMySubscriptionCount(message: string) {
    this.subscriptionCountsSubject.next({ text: message });
  }

  clearMySubscriptionCount() {
    this.subscriptionCountsSubject.next();
  }

  getMySubscriptionCount(): Observable<any> {
    return this.subscriptionCountsSubject.asObservable();
  }

  sendManagedCount(message: string) {
    this.managedCountsSubject.next({ text: message });
  }

  clearManagedCount() {
    this.managedCountsSubject.next();
  }

  getManagedCount(): Observable<any> {
    return this.managedCountsSubject.asObservable();
  }

  sendcustomEditSubAttributeData(message: any) {
    this.customEditSubAttributeData.next({ text: message });
  }

  getcustomEditSubAttributeData(): Observable<any> {
    return this.customEditSubAttributeData.asObservable();
  }


  sendcustomFilterSubAttributeData(message: any) {
    this.customFilterSubAttributeData.next({ text: message });
  }

  getcustomFilterSubAttributeData(): Observable<any> {
    return this.customFilterSubAttributeData.asObservable();
  }

  clearcustomFilterSubAttributeData() {
    this.customFilterSubAttributeData.next();
  }


  sendFilterDataInHome(field, selectedValue) {
    if (!this._homeFilterCriteria.hasOwnProperty(field.name)) {
      Object.assign(this._homeFilterCriteria, { [field.name]: selectedValue })
    } else {
      this._homeFilterCriteria[field.name] = selectedValue;
      if (field.dependent && field.dependent.isDependentTo) {
        delete this._homeFilterCriteria[field.dependent.dependentField];        
      }
      if (selectedValue === '') {
        delete this._homeFilterCriteria[field.name];
      }
    }
    //if(JSON.stringify(this._homeFilterCriteria)!=='{}'){
    this.filterCriteriaDataToHome.next({ text: JSON.stringify(this._homeFilterCriteria) });
   // }
  }

  getFilterDataInHome(): Observable<any> {
    return this.filterCriteriaDataToHome.asObservable();
  }

  clearFilterDataInHome() {
    this.filterCriteriaDataToHome.next();
  }


  sendNotificationType(message: any) {
    this.selectedNotificationType.next({ text: message });
  }

  clearNotificationType() {
    this.selectedNotificationType.next();
  }

  getNotificationType(): Observable<any> {
    return this.selectedNotificationType.asObservable();
  }

  sendIsIntermodal(message: string) {
    this.isIntermodalNotification.next(message);
  }
  getIsIntermodal(): Observable<any>{
    return this.isIntermodalNotification.asObservable();
  }

}
