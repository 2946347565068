import { Injectable } from '@angular/core';
// import { LocalizationService } from '@cn/mobile-core';
import { APP_LANGUAGE, AvailableLanguages, defaultLanguage } from '@src/app/constants/NAASLang';
//import { LocalizationService } from 'mobile-core/src/lib/localization/localization.service';
import { CommonService } from '../services/common.service';
import { TranslateService } from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  setLang;
  selected;
  constructor( private commonService: CommonService, public translate: TranslateService) { 
    if (this.commonService.selectedLang == '') {
      this.setDefaultLang(defaultLanguage);
    } else {
      this.setDefaultLang((this.commonService.selectedLang).toString());
    }
    this.setLanguages(Object.values(AvailableLanguages));
    this.setLanguageOnLoad();
  }

  setLanguageOnLoad() {
    if (this.commonService.selectedLang == '') {
      this.translateMsg(defaultLanguage);
    } else {
      this.translateMsg((this.commonService.selectedLang).toString())
    }
  }

  getCurrentLanguage() {
    let l = this.getCurrentLang();
    this.translateMsg(defaultLanguage);
    return l;
  }

  getAvailableLanguages() {
    return Object.values(AvailableLanguages);
  }

  setCurrentLanguage(lang: AvailableLanguages) {
    this.translateMsg(lang);
    this.commonService.selectedLang = lang.toString();
  }



  defaultLang: string = "en";
  /**
   * The setLanguages method sets all the required languages. It accepts language array as a parameter.
   * @param langList
   * @memberof LocalizationService
   */
  setLanguages(langList) {
    this.translate.addLangs(langList);
  }
  /**
   * The setDefaultLang method sets the default language. It accepts default language code as a parameter. SDK will set 'en' as default language code if the parameter is blank.
   * @param defaultLang
   * @memberof LocalizationService
   */
  setDefaultLang(defaultLang) {
    if (defaultLang !== "") {
      this.translate.setDefaultLang(defaultLang);
    } else {
      this.translate.setDefaultLang(this.defaultLang);
    }
  }
  /**
   * The getLanguages method fetches all the language codes that is set by setDefaultLang method. It doesn't accepts any input parameters.
   * @memberof LocalizationService
   */
  getLanguages() {
    return this.translate.getLangs();
  }
  /**
   * The getCurrentLang method fetches the current language. This method doesn't accept any parameters.
   * @memberof LocalizationService
   */
  getCurrentLang() {
    return this.translate.currentLang;
  }
  /**
   * The translateMsg method changes the language of the text as per the given language code. This method accepts language code as a parameter and doesn't return any thing.
   * @param language
   * @memberof LocalizationService
   */
  translateMsg(language) {
    this.translate.use(language);
  }
  /* It will translate the message to selected language
   *
   *
   * @param {*} language
   * @memberof LocalizationService
   */
 get(key:string|string[]){
   return this.translate.get(key);
 }

}
