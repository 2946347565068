import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageService } from '../language.service';
import { CommonService } from '@src/app/services/common.service';
import { SubscriptionService } from '@src/app/services/subscription.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  langs = [];
  currentLang;

  constructor(
    private languageService: LanguageService,
    private cdRef: ChangeDetectorRef,
    public subscriptionService: SubscriptionService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.currentLang = JSON.parse(sessionStorage.getItem('language'));
    if (this.currentLang === null || this.currentLang === '') {
      this.currentLang = 'en';
    }
    this.languageService.setCurrentLanguage(this.currentLang);
    sessionStorage.setItem('language', JSON.stringify(this.currentLang));
    this.currentLang = this.currentLang == 'en' ? 'fr' : 'en';
    this.cdRef.detectChanges();
  }

  setLanguage(lang) {
    this.languageService.setCurrentLanguage(lang);
    sessionStorage.setItem('language', JSON.stringify(lang));
    this.currentLang = lang == 'en' ? 'fr' : 'en';
    this.subscriptionService.sendLangSwitcherData(
      JSON.stringify(lang)
    );
    this.cdRef.detectChanges();
  }
}
