import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
// import { UserService, DataService, DataStoreType } from '@cn/mobile-core';
import { DataService } from '@cn/mobile-core';
import {
  UserService,
} from '@cn/mobile-core';

import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ADGroupGuard implements CanActivate {
    dataService = new DataService();
    userService = new UserService();
    constructor( private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let adGrp = sessionStorage.getItem('ad_groups');
        let groupUsers = JSON.parse(adGrp);
        const hasData = this.hasGroup(groupUsers);
        if (hasData) {
            return true;
        } else {
            if (route.data.hasOwnProperty('redirectPath')) {
                this.router.navigate([route.data.redirectPath]);
            }
            return false;
        }
    }

    public hasGroup(group: string | string[]) {
        let hasData: string[];
        hasData = this.getGroups();
        hasData = hasData.map(function (x) {
          return x.toUpperCase();
        });
        if (Array.isArray(group)) {
          group = group.map(function (x) {
            return x.toUpperCase();
          });
          return hasData.some(obj => group.indexOf(obj) >= 0);
        } else {
          return hasData.includes(group.toString().toUpperCase());
        }
      }

      public getGroups(): string[] {
        let jwtToken = sessionStorage.getItem('jwtToken');

        let groupArray: string[];
        groupArray = [];
        if (jwtToken) {
            groupArray = JSON.parse(jwtToken).groups
        //   Object.keys(activeUser._socialIdentity['kinveyAuth']).forEach(key => {
        //     if (key.includes('groupmember')) {
        //       groupArray.push(activeUser._socialIdentity['kinveyAuth'][key]);
        //     }
        //   });
          return groupArray;
        } else {
          return [];
        }
      }
}
