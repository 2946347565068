import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    pageT: string;
}

@Component({
    selector: 'alert-dialog',
    templateUrl: './dialog.component.html'
})
export class DialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialog: MatDialog, public dialogRef1: MatDialogRef<DialogComponent>) {}

    close() {
        this.dialogRef1.close();
    }

}
