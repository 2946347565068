import {
  Component,
  NgZone,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { DataService } from '@cn/mobile-core';
import { UserService } from '@cn/mobile-core';
import { SubscriptionService } from '@src/app/services/subscription.service';
import { Subscription } from 'rxjs';
import { CustomUIService } from '../../../services/custom-attribute.service';
import { CustomEndPointService, where } from '@cn/mobile-core';
import { CommonService } from '@src/app/services/common.service';
@Component({
  selector: 'app-select',
  template: `<div>
      <label [ngStyle] = "disabledCheck(field) ? {'color': 'rgba(0, 0, 0, 0.38)'}:{'color': '#646F7D'}"
        >{{ field.label[language]
        }}<span
          *ngIf="
            field.validations[0].name == 'required' &&
            field.validations[0].validator
          "
          >*</span
        ></label
      >
    </div>
    <mat-form-field [formGroup]="group">
      <mat-select [disabled]="disabledCheck(field)"
        [formControlName]="field.name"
        (selectionChange)="check($event.value)"
        [id]="field.name"
      >
        <ng-container
          *ngIf="
            isCompanySelected == 'N' ||
            (isCompanySelected == 'Y' && options.length > 0)
          "
        >
          <mat-option value="">{{ field.placeholder[language] }} </mat-option>
        </ng-container>
        <ng-container
          *ngIf="
            isCompanySelected == 'Y' &&
            field.name == 'location' &&
            options.length <= 0
          "
        >
          <mat-option value="">
            {{ field.noResults[language] }}
          </mat-option>
        </ng-container>
        <ng-container
          *ngIf="
            isIntermodalFromService == 'N' ||
            !isIntermodalFromService ||
            field.name == 'company'
          "
        >
          <mat-option
            *ngFor="let item of options"
            [value]="item[field.codeToShowInDropdown]"
            >{{ item[field.valueToShowinDropdown] }}</mat-option
          >
        </ng-container>
        <ng-container
          *ngIf="isIntermodalFromService == 'Y' && field.name == 'location'"
        >
          <mat-option
            *ngFor="let item of options"
            [value]="
              item[field.codeToShowInDropdownForIntermodal2] +
              item[field.codeToShowInDropdownForIntermodal]
            "
            >{{ item[field.valueToShowinDropdownForIntermodal] }}
          </mat-option>
        </ng-container>
      </mat-select>
      <ng-container
        *ngFor="let validation of field.validations"
        ngProjectAs="mat-error"
      >
        <mat-error *ngIf="group.get(field.name).hasError(validation.name)">
          <span class="icon-notification2" style="color: brown;"> </span>
          {{ validation.message[language] }}</mat-error>
      </ng-container>
      <mat-hint *ngIf= "field.modeOfOperation == 'filterSubscriptions' && field.name == 'company'">
       <span *ngIf = "disabledCheck(field) == false && this.isCompanySelected == 'N'" style="color: #323A45;"><img src="../assets/Img/hintIcon.svg" alt="ICON" style="
       vertical-align: text-bottom;"/>{{ field.companyHint[language]}}</span>
      </mat-hint>
    </mat-form-field> `,

  styles: [],
})
export class SelectComponent implements OnInit {
  field: any;
  group: FormGroup;
  index: any;
  collection: any;
  options = [];
  disable = false;
  selected: any;
  language: any;
  fieldLabel: any;
  customAttributeData = [];
  notificationTypeData: boolean = false;
  compRef: any;
  CASubscription: Subscription;
  notificationSubscription: Subscription;
  CLSubscription: Subscription;
  formType: any;
  _filterCriteria: any = {};
  isIntermodal: string = 'N';
  isIntermodalSubscription: Subscription;
  isIntermodalFromService: string;
  isCompanySelected: string =
    sessionStorage.getItem('isCompanySelected') === 'Y' ? 'Y' : 'N';
  userService = new UserService();
  dataService = new DataService();
  customEndpointService = new CustomEndPointService();
  editingNotificationTypeCode : any;
  constructor(
    private zone: NgZone,
    private changeDetectorRefs: ChangeDetectorRef,
    private fb: FormBuilder,
    private elRef: ElementRef,
    public subscriptionService: SubscriptionService,
    public customUI: CustomUIService,
    public commonService : CommonService
  ) {
    this.CASubscription = this.subscriptionService
      .getcustomAddSubAttributeData()
      .subscribe((data) => {
        let addData = JSON.parse(data.text);
        if (addData) {
          this.customAttributeData = addData;
        }
      });

    this.isIntermodalSubscription = this.subscriptionService
      .getIsIntermodal()
      .subscribe((data) => {

        let isIntermodalData = data;
        if (isIntermodalData) {
          this.isIntermodalFromService = isIntermodalData;
        }
      });

    this.notificationSubscription = this.subscriptionService
      .getNotificationType()
      .subscribe((data) => {
        this.editingNotificationTypeCode = data.text;
        let addData = JSON.parse(data.text);
        if (addData) {
          this.notificationTypeData = addData;
          this.group.get(this.field.name).setValue('', { emitEvent: false });

          
        }
      });

    this.CLSubscription = this.subscriptionService
      .getcustomFilterSubAttributeData()
      .subscribe((data) => {
        let addData = JSON.parse(data.text);
        if (addData) {
          this.customAttributeData = addData;
        }
      });
  }
  disabledCheck(field) {
    //console.log("sessionStorage.getItem('selectedCategoryType')" ,sessionStorage.getItem('selectedCategoryType'));
    
    let notificationCategories = this.field.loggedInWith.isam.notificationCategories;
    //console.log("check field" ,notificationCategory);
    //console.log("check field notificationCategories" ,notificationCategories);
    if(field.modeOfOperation == "filterSubscriptions") {
      if(this.commonService.getSelectCategory == 'N' || this.commonService.getSelectCategory == undefined) {
        this.isCompanySelected='N'
      }
      
      //this.group.disable();
     // console.log("companyValue", this.isCompanySelected)
    //   if (field.dependent.isDependent !== undefined && field.dependent.isDependent && this.isCompanySelected == 'Y') {
    //     return false;
    //  } else if(field.dependent.isDependentTo !== undefined && this.commonService.getIsDisabledCust) {
    //   return false;
    //  } else if(this.commonService.getSelectCategory == 'N') {
    //   return true;
    //  }
    
    //  return true;
  
    if(this.field.loggedInWith.isam.notificationCategories !==undefined&& sessionStorage.getItem('selectedCategoryType') !== undefined && sessionStorage.getItem('selectedCategoryType')!==null){
    const notificationCategory = JSON.parse(sessionStorage.getItem('selectedCategoryType')).category;
      //console.log("in 11111",notificationCategories.includes(notificationCategory))
      if(!this.field.loggedInWith.isam.notificationCategories.includes(notificationCategory) && this.isCompanySelected == 'Y') {
        //console.log("in 22222");
        return true
      }
    } 
     if(this.commonService.getSelectCategory == 'Y' && field.name == 'company') {
      return false
    } else if(this.commonService.getIsDisabledCust && this.isCompanySelected == 'Y') {
      return false
       
    }else {
      //console.log("in 777777");
      return true
    }
  } else if(field.modeOfOperation == "addSubscription" || field.modeOfOperation == "editSubscription") {
    const selNotifType = JSON.parse(sessionStorage.getItem('selectedNotificationType'));
    // console.log('selNotifType: ',selNotifType)
    // console.log('sessionStorage.getItem(isIntermodal): ',sessionStorage.getItem('isIntermodal'))
    // console.log('field.dependent.isDependent: ',field.dependent.isDependent)
    if(sessionStorage.getItem('isIntermodal')=='NA' && field.dependent.isDependent ){
      return true
    } 
      return false
    
  }
}
  check(x) {
    sessionStorage.setItem('isCompanySelected', 'Y');
    if (
      this.field.modeOfOperation === 'addSubscription' ||
      this.field.modeOfOperation == 'editSubscription' ||
      this.field.modeOfOperation == 'filterSubscriptions'
    ) {
      if (
        this.field.dependent &&
        this.field.dependent.isDependentTo !== undefined &&
        this.field.dependent.isDependentTo
      ) {
        this.getDependentData(
          x,
          this.field.dependent.dependentField,
          this.field.dependent.keyNameToQuery,
          this.field.dependent.keyNameToIsIntermodal
        );
      }
      this.customAttributeData.forEach((data) => {
        if (data.name === this.field.name) {
          data.selectedValue = x;
        }
      });

      if (
        this.field.modeOfOperation == 'filterSubscriptions' &&
        this.field.filterCriteria
      ) {
        this.subscriptionService.sendFilterDataInHome(this.field, x);
      }
    }
  }

 async ngOnInit() {
    if (this.customAttributeData && this.customAttributeData.length === 0) {
      this.getAddCustomAttributeConfig();
    }
    this.language = JSON.parse(sessionStorage.getItem('language')) || 'en';
    if (
      this.field.modeOfOperation === 'addSubscription' ||
      this.field.modeOfOperation === 'editSubscription' ||
      this.field.modeOfOperation == 'filterSubscriptions'
    ) {
      if (
        this.field.selectedValue !== undefined &&
        this.field.selectedValue !== ''
      ) {
        this.group
          .get(this.field.name)
          .setValue(this.field.selectedValue, { emitEvent: false });
        sessionStorage.setItem('isCompanySelected', 'Y');
      } else {
        if (this.field.name === 'company') {
          sessionStorage.setItem('isCompanySelected', 'N');
        }
        
        this.group.controls[this.field.name].setValue('', { emitEvent: false });
      }
    }
    this.loadInfo();
    this.loadInfoInHome();
    if (this.userService.getCurrentUser()) {
      const user = this.userService.getCurrentUser();
      const queryConstraint: any = [where('uid', '==', user.uid)];

      let userContext: any = this.dataService
        .collection('UserContext')
        .find(queryConstraint);
      let userDetails: any;
      //async(element )=>{
      if (Object.keys(userContext).length !== 0) {
        (await userContext).forEach((doc) => {
          userDetails = doc.data();
        });
      }
    //}
      if (
        userDetails.idTokenWithContext.loggedInWith === 'aad' &&
        this.field &&
        this.field.loggedInWith.aad.display
      ) {
        if (this.field.modeOfOperation === 'editSubscription') {
          if (!this.field.loggedInWith.aad.isEditable) {
            this.disable = true;
          }
          this.group
            .get(this.field.name)
            .setValue(this.field.loggedInWith.aad.selectedValue, {
              emitEvent: false,
            });
        }
      }
      if (
        userDetails.idTokenWithContext.loggedInWith === 'isam' &&
        this.field &&
        this.field.loggedInWith.isam.display
      ) {
        if (this.field.modeOfOperation === 'editSubscription') {
          if (!this.field.loggedInWith.isam.isEditable) {
            this.disable = true;
          }
          this.group
            .get(this.field.name)
            .setValue(
              this.field.selectedValue
                ? this.field.selectedValue
                : this.field.loggedInWith.isam.selectedValue,
              { emitEvent: false }
            );
        }
      }
    }
  }

  async loadInfo() {
    let query: any;
    if (this.field.options.length === 0) {
      if (this.field.toFetchFromCollection != ' ') {
        if (this.field.isQueryByKey != '' && this.field.isQueryByValue != '') {
          // query = [
          //   where(this.field.isQueryByKey, '==', this.field.isQueryByValue),
          // ];
          query = {
            key: this.field.isQueryByValue,
          };
        }
        if (this.field.modeOfOperation == 'editSubscription') {
          this.customUI.setEditSelected(
            this.field.name,
            this.field.loggedInWith.isam.selectedValue
          );
          
          if (
            this.field.loggedInWith.isam.selectedValue !== undefined &&
            this.field.loggedInWith.isam.selectedValue !== ''
          ) {
            sessionStorage.setItem('isCompanySelected', 'Y');
          }
          if (
            this.field.dependent.isDependent &&
            this.field.dependent.fetchOnLoad != undefined &&
            this.field.dependent.fetchOnLoad != ''
          ) {
            const customUi = this.customUI.getCustomAttirbuteList();
            let fullObject = {};
            if (
              customUi[this.field.dependent.dependentOn] &&
              customUi[this.field.dependent.dependentOn].length > 0
            ) {
              const editSelected = this.customUI.getEditSelected();
              fullObject = customUi[this.field.dependent.dependentOn].find(
                (xy) => {
                  return (
                    xy[this.field.dependent.dependentId] ===
                    editSelected[this.field.dependent.dependentOn]
                  );
                }
              );
            }
            // query = [
            //   where(
            //     this.field.dependent.fetchOnLoad.keyNameToQuery,
            //     '==',
            //     fullObject[
            //       this.field.dependent.fetchOnLoad.keyNameToFetchDependentValue
            //     ]
            //   ),
            //   where(
            //     this.field.dependent.fetchOnLoad.dependentKey,
            //     '==',
            //     this.field.dependent.fetchOnLoad.dependentField
            //   ),
            // ];
            
            await this.findIsIntermodalFlag();
            this.isIntermodal = sessionStorage.getItem('isIntermodal');
            //this.isIntermodal ='Y';
            // query.push(
            //   where(
            //     this.field.dependent.fetchOnLoad.keyNameToIsIntermodal,
            //     '==',
            //     this.isIntermodal
            //   )
            // );
            query = {
            
              cust633Id: fullObject[this.field.dependent.fetchOnLoad.keyNameToFetchDependentValue],
              key: this.field.dependent.fetchOnLoad.dependentField,
              isIntermodal: this.isIntermodal
              
            };
          }
        }
        

        if (
          (this.field.modeOfOperation == 'addSubscription' &&
            !this.field.dependent.isDependent) ||
          this.field.modeOfOperation == 'editSubscription'
        ) {
          try {
          this.customEndpointService
            .execute('naasCustomerNotification', query)
            .then(
              (customEndPointResponse) => {
                
                


         // const addSubscriptionDtls: any = this.collection.find(query);
          
           var data: any = customEndPointResponse.response.data;
           

              this.zone.run(() => {
                if (
                  this.isIntermodalFromService === 'N' ||
                  !data.list[this.field.toFetchFromIntermodalObject]
                ) {
                  this.options = data.list[this.field.toFetchFromObject];

                  
                  this.customUI.setCustomAttirbuteList(
                    this.field.name,
                    data.list[this.field.toFetchFromObject]
                  );
                } else {
                  this.options = data.list[this.field.toFetchFromIntermodalObject];
                  this.customUI.setCustomAttirbuteList(
                    this.field.name,
                    data.list[this.field.toFetchFromIntermodalObject]
                  );
                }
              });
              });
            
          } catch (err) {
            this.loadInfo();
          }

          //.subscribe((data: any) => {
        } else {
          this.options = [];
        }
      }
    } else {
      this.options = this.field.options;
    }
  }
  findIsIntermodalFlag() {
    return new Promise(async (resolve, reject) => {
      let notificationConfigcollection = this.dataService.collection(
        'notificationTypeConfig'
      );
      const selectedNotificationType =  sessionStorage.getItem('editingNotificationTypeCode')


      let notificationTypeConfigQuery = [
        where('notificationTypeCode', 'array-contains', selectedNotificationType),
      ];
      //setTimeout(() => {
      const notificationConfigcollectionDtls =
        await notificationConfigcollection.find(notificationTypeConfigQuery);
      if (Object.keys(notificationConfigcollectionDtls).length !== 0) {
        let entities: any;
        (await notificationConfigcollectionDtls).forEach((doc) => {
          entities = doc.data();
        });

        //this.isIntermodal = entities[0].isIntermodal;
        
        this.subscriptionService.sendIsIntermodal(entities.isIntermodal);
        this.isIntermodal = entities.isIntermodal;
        sessionStorage.setItem('isIntermodal', entities.isIntermodal);
        resolve(entities);
      }
      //},2000
      //  );
    });
  }

  loadInfoInHome() {
    let query: any;
    if (this.field.options.length === 0) {
      if (this.field.toFetchFromCollection != ' ') {
        if (this.field.isQueryByKey != '' && this.field.isQueryByValue != '') {
          query = {
            key: this.field.isQueryByValue,
          };
        }

        if (this.field.modeOfOperation == 'filterSubscriptions' && query != undefined) {
          this.customEndpointService
            .execute('naasCustomerNotification', query)
            .then(
              (customEndPointResponse) => {
                

                this.options =
                  customEndPointResponse.response.data.list[
                    this.field.toFetchFromObject
                  ];

                this.customUI.setCustomAttirbuteList(
                  this.field.name,
                  customEndPointResponse.response.data.list[
                    this.field.toFetchFromObject
                  ]
                );
              },
              (error) => {}
            );
        } else {
          this.options = [];
        }
      }
    } else {
      this.options = this.field.options;
    }
  }

  getDependentData(
    selectedValue,
    dependentFieldName,
    keyNameToQuery,
    keyNameToIsIntermodal
  ) {
    let obj;
    this.customAttributeData.forEach((field) => {
      if (field.name === dependentFieldName) {
        obj = field;
      }
    });
    this.fetchDependentData(
      selectedValue,
      obj.toFetchFromCollection,
      dependentFieldName,
      keyNameToQuery,
      keyNameToIsIntermodal
    );
  }

  async getAddCustomAttributeConfig() {
    let query: any;
    if (this.formType == 'Add') {
      query = [where('modeOfOperation', '==', 'addSubscription')];
    } else if (this.formType == 'Edit') {
      query = [where('modeOfOperation', '==', 'editSubscription')];
    } else {
      query = [where('modeOfOperation', '==', 'filterSubscriptions')];
    }
    let collection = this.dataService.collection('customAttribute');

    const customAttributeCollection: any = collection.find(query);
    if (Object.keys(customAttributeCollection).length !== 0) {
      let data: any;
      (await customAttributeCollection).forEach((doc) => {
        data = doc.data();
        if (data) {
          this.customAttributeData.push(data);
        }
      });
    }
  }

  async fetchDependentData(
    selected,
    collectionName,
    dependentFieldName,
    keyNameToQuery,
    keyNameToIsIntermodal
  ) {
    if (selected === '' || selected === null || selected === undefined) {
      this.customAttributeData.forEach((element) => {
        if (element.name === dependentFieldName) {
          element.options = [];
          this.customUI.setCustomAttirbuteList(element.name, []);
        }
      });
      this.updateSubjectWithData();
      return;
    }
    let collection = this.dataService.collection(collectionName);

    let notificationConfigcollection = this.dataService.collection(
      'notificationTypeConfig'
    );
    const selectedNotificationType = JSON.parse(
      sessionStorage.getItem('selectedNotificationType')
    );

    //console.log('selectedNotificationTypeCode', selectedNotificationType.code);

    let notificationTypeConfigQuery = [
      where('notificationTypeCode', 'array-contains', selectedNotificationType),
    ];

    let getIntermodalValue = await notificationConfigcollection.find(
      notificationTypeConfigQuery
    );
    if (Object.keys(getIntermodalValue).length !== 0) {
      // (await getIntermodalValue).forEach((doc) => {
      //   let data = doc.data();
      //   if (data) {
      //     console.log('getIntermodal Value data: ', data);
      //   }
      // });
      let entities: any;
      //this.isIntermodal = entities[0].isIntermodal;
      

      sessionStorage.setItem(
        'isIntermodal',
        getIntermodalValue.docs[0].data().isIntermodal
      );
      this.fetchCustomAttributesData(
        selected,
        keyNameToQuery,
        collection,
        dependentFieldName
      );
    }
  }

  fetchCustomAttributesData(
    selected,
    keyNameToQuery,
    collection,
    dependentFieldName
  ) {
    this.isIntermodal = sessionStorage.getItem('isIntermodal');
    this.subscriptionService.sendIsIntermodal(this.isIntermodal);

    

    let query: any = [];

    const customUi = this.customUI.getCustomAttirbuteList();
    let fullObject = {};
    if (customUi[this.field.name] && customUi[this.field.name].length > 0) {
      fullObject = customUi[this.field.name].find((xy) => {
        return xy[this.field.codeToShowInDropdown] === selected;
      });
    } 
    // else{
    //   fullObject = customUi[this.field.name].find(xy => {
    //     return xy[this.field.codeToShowInDropdown] === selected
    //   })
    // }
    
    query = {
      [keyNameToQuery]:
        fullObject[this.field.dependent.keyNameToFetchDependentValue],
      [this.field.dependent.dependentKey]: this.field.dependent.dependentField,
      [this.field.dependent.keyNameToIsIntermodal]: this.isIntermodal
    };
    // query = [
    //   where(
    //     'keyNameToQuery',
    //     '==',
    //     fullObject[this.field.dependent.keyNameToFetchDependentValue]
    //   ),
    //   where(
    //     this.field.dependent.dependentKey,
    //     '==',
    //     this.field.dependent.dependentField
    //   ),
    //   where(
    //     this.field.dependent.keyNameToIsIntermodal,
    //     '==',
    //     this.isIntermodal
    //   ),
    // ];

    //}
    

    //const collectionOutput = collection.find(query);
    if(this.isIntermodal!=='NA'){
    this.customEndpointService
      .execute('naasCustomerNotification', query)
      .then((customEndPointResponse) => {
        
        if (customEndPointResponse.response.data !== undefined) {
          // if (Object.keys(collectionOutput).length !== 0) {
          //   let data: any;
          //   (await collectionOutput).forEach((doc) => {
          //     data = doc.data();
          //   });

          // if (data) {
          //   console.log('data', data);
          this.customAttributeData.forEach((element) => {

            if (element.name === dependentFieldName) {
              if (this.isIntermodal === 'Y') {
                
                element.options =
                  customEndPointResponse.response.data.list[
                    this.field.dependent.toFetchFromIntermodalObject
                  ];
                this.customUI.setCustomAttirbuteList(
                  element.name,
                  customEndPointResponse.response.data.list[
                    this.field.dependent.toFetchFromIntermodalObject
                  ]
                );
              } else {
                element.options =
                  customEndPointResponse.response.data.list[
                    this.field.dependent.toFetchFromObject
                  ];
                this.customUI.setCustomAttirbuteList(
                  element.name,
                  customEndPointResponse.response.data.list[
                    this.field.dependent.toFetchFromObject
                  ]
                );
              }
              
            } else {
              //element.options = "No Data Available"
            }
          });
          this.updateSubjectWithData();
        } else {
        }
      });
    }
  }

  updateSubjectWithData() {
    
    if (this.field.modeOfOperation === 'addSubscription') {
      this.subscriptionService.sendcustomAddSubAttributeData(
        JSON.stringify(this.customAttributeData)
      );
    }
    if (this.field.modeOfOperation == 'editSubscription') {
      this.subscriptionService.sendcustomEditSubAttributeData(
        JSON.stringify(this.customAttributeData)
      );
    }
    if (this.field.modeOfOperation == 'filterSubscriptions') {
      
      this.subscriptionService.sendcustomFilterSubAttributeData(
        JSON.stringify(this.customAttributeData)
      );
    }
  }
}
