import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SubscriptionRoutingModule } from '@src/app/subscription/subscription-routing.module';
import { AddEditSubscriptionComponent } from '@src/app/subscription/add-edit-subscription/add-edit-subscription.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '@src/app/shared/shared.module';
import { DialogComponent } from '@src/app/dialog.component';
import { LoaderComponent } from '@src/app/loader.component';
import { SuccessSnackbarComponent } from '@src/app/subscription/success-snackbar/success-snackbar.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DataErrorSnackbarComponent } from './dataerror-snackbar/dataerror-snackbar.component';
import { SubscriptionService } from '../services/subscription.service';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core'; 

@NgModule({
  declarations: [
    AddEditSubscriptionComponent,
    DialogComponent,
    LoaderComponent,
    SuccessSnackbarComponent,
    DataErrorSnackbarComponent
  ],
  entryComponents: [
    AddEditSubscriptionComponent,
    DialogComponent,
    LoaderComponent,
    SuccessSnackbarComponent,
    DataErrorSnackbarComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    SubscriptionRoutingModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatMenuModule,
    NgxIntlTelInputModule,
    MatProgressBarModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    TranslateModule
  ],
  exports: [
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatMenuModule,
    MatProgressBarModule,
    AddEditSubscriptionComponent,
    LoaderComponent,
    DialogComponent,
    SuccessSnackbarComponent,
    DataErrorSnackbarComponent,
  ],
  providers: [SubscriptionService],
})
export class SubscriptionModule {}
