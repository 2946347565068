import { MOBILE_CORE_TARGET_FRAMEWORK } from "@cn/mobile-core";

export const environment = {
    production: true,
    firebaseConfig : {
      apiKey: '#{API_KEY}#',
      authDomain: '#{AUTH_DOMAIN}#',
      projectId: '#{PROJECT_ID}#',
      storageBucket: '#{STORAGE_BUCKET}#',
      messagingSenderId: '#{MESSAGING_SENDER_ID}#',
      appId: '#{APP_ID}#',
      measurementId: '#{MEASUREMENT_ID}#',
      databaseURL: ""
    },
    mobileCoreConfig: {
      logService: {
        enabled: true,
      },
      customEndPointService: {
        glbHostname: "#{GLB_HOSTNAME}#",
      },
      userService: {
        glbHostname: "#{GLB_HOSTNAME}#",
      },
      targets: {
        framework : MOBILE_CORE_TARGET_FRAMEWORK.ANGULAR_WEB
      }
    },
  };
