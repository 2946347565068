import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { LanguageService } from "@src/app/shared/language.service";

@Component({
  selector: 'app-success-snackbar',
  templateUrl: './success-snackbar.component.html',
  styleUrls: ['./success-snackbar.component.scss']
})
export class SuccessSnackbarComponent implements OnInit {
  currentLanguage = 'en';
  constructor(public snackBar: MatSnackBar,
    public languageSer: LanguageService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { 
      this.currentLanguage = languageSer.getCurrentLanguage();
  }

  ngOnInit(): void {
  }

  closeSnackbar() {
    this.snackBar.dismiss();
  }

}
