import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatRadioChange } from '@angular/material/radio';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DialogComponent } from '../../dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@src/app/shared/language.service';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SuccessSnackbarComponent } from '../success-snackbar/success-snackbar.component';
import { DataErrorSnackbarComponent } from '../dataerror-snackbar/dataerror-snackbar.component';
import { SubscriptionService } from '@src/app/services/subscription.service';

import { DataService } from '@cn/mobile-core';

import { CustomUIService } from '../../services/custom-attribute.service';
import { CustomEndPointService, where } from '@cn/mobile-core';
import { UserService } from '@cn/mobile-core';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
export interface Notification {
  _id: string;
  _kmd: any;
  _acl: any;
  id: string;
  channel: string;
  deliveryAddress: any;
  language: string;
  notificationTypeId?: string;
  notificationCode: string;
  notifTypeDisplayName: String;
}

export class State {
  constructor(
    public name: string,
    public population: string,
    public flag: string
  ) {}
}

@Component({
  selector: 'app-add-edit-subscription',
  templateUrl: './add-edit-subscription.component.html',
  styleUrls: ['./add-edit-subscription.component.scss'],
})
export class AddEditSubscriptionComponent implements OnInit {
  notification = [];
  pageType: string = 'Add';
  isPageAdd: boolean = true;
  isLoader: boolean = true;
  isCustomerAttrLoader: boolean = true;
  isValidFormSubmitted = false;
  notificationType: string;
  notificationtypetxt: string;
  channelType: string;
  channelTypeTxt: any;
  languge: string;
  notificationSelect = '';
  isEmail: boolean = false;
  isSms: boolean = false;
  isVoice: boolean = false;
  isEnglish: string = 'en';
  submitted = false;
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$';
  channelOptions: string;
  languageOptions: string;
  collection = 'Subscriptions';
  notificationTypeCollection = 'notificationTypes';
  NOTIFICATION_DATA: Notification;
  isValuesFilled: boolean = false;
  pageTypeText = '';
  loading = false;
  saveClicked: boolean = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  emaildisabled: boolean = false;
  smsdisabled: boolean = false;
  voicecalldisabled: boolean = false;
  endisabled: boolean = false;
  frdisabled: boolean = false;
  apiResponse: any[];
  selectedResult: any;
  selectedLanguage: any;
  emailcheck: boolean = false;
  smscheck: boolean = false;
  voicecallcheck: boolean = false;
  encheck: boolean = false;
  frcheck: boolean = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Canada,
    CountryISO.UnitedStates,
    CountryISO.India,
  ];
  upn: any;
  isManager: Boolean;
  stateCtrl: FormControl;
  filteredStates: any = [];
  @Input() fields: any;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  subscriptionform: FormGroup;

  @ViewChild('stateInput') stateInput: ElementRef;
  selectedUser: any;
  userCheck: boolean = false;
  isloggedInWith: any;
  userLoginType: boolean = false;
  customAddSubscription: any = [];
  customEditSubscriptionData: any;
  customEditSubscription: any = [];
  formControlNameList = [];
  uiCustomSave: any[];
  editNaasOriginalInfo: {};
  CASubscription: Subscription;
  CEditSubscription: Subscription;
  private dataService = new DataService();
  private customEndpointService = new CustomEndPointService();
  public userService = new UserService();
  constructor(
    public route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public languageSer: LanguageService,
    public router: Router,
    public addEditPopup: MatDialogRef<AddEditSubscriptionComponent>,
    public subscriptionService: SubscriptionService,
    public CustomUIService: CustomUIService,

    public changeDetectorRefs: ChangeDetectorRef
  ) {
    this.pageType = this.route.snapshot.paramMap.get('type');
    if (this.pageType == 'Add') {
      this.isPageAdd = true;
      this.isEmail = true;
      this.isSms = false;
      this.isVoice = false;
      this.pageTypeText = 'HOME.ADD';
    } else {
      this.loading = true;
      this.isPageAdd = false;
      this.pageTypeText = 'HOME.EDIT';
      this.getAllNotificationTypesOnEdit(
        this.route.snapshot.paramMap.get('id')
      );
    }
    this.getCustomConfiguration();
    this.subscriptionform = this.formBuilder.group({
      notificationtypetxt: ['', Validators.required],
      channeltype: ['', Validators.required],
      channeltypeSMStxt: ['', [Validators.required]],
      channeltypeVoicetxt: ['', [Validators.required]],
      language: ['', Validators.required],
      email: ['', Validators.required],
      userFilter: [''],
    });

    this.isManager = JSON.parse(sessionStorage.getItem('isManager'));

    this.isloggedInWith = sessionStorage.getItem('loggedInWith');

    if (this.isloggedInWith == 'isam') {
      this.userLoginType = true;
    } else {
      this.userLoginType = false;
    }

    this.CASubscription = this.subscriptionService
      .getcustomAddSubAttributeData()
      .subscribe((data) => {
        let addData = JSON.parse(data.text);
        if (addData) {
          addData.sort(
            (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
          );
          this.customAddSubscription = addData;
          this.createControl(this.customAddSubscription);
        }
      });
    this.CEditSubscription = this.subscriptionService
      .getcustomEditSubAttributeData()
      .subscribe((data) => {
        let addData = JSON.parse(data.text);
        if (addData) {
          this.customEditSubscription = addData;
          this.customEditSubscription = this.customEditSubscription.sort(
            (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
          );
          this.createControl(this.customEditSubscription);
        }
      });
    this.formControlValueChanged();
  }

  async getCustomConfiguration() {
    await this.getConfiguration();
  }

  createControl(fields) {
    const group = this.subscriptionform;
    fields.forEach((field) => {
      if (
        this.isloggedInWith === field.loggedInWith.aad.isloggedIn &&
        field &&
        field.loggedInWith.aad.display
      ) {
        const control = this.formBuilder.control(
          field.value,
          this.bindValidations(field.validations || [])
        );
        group.addControl(field.name, control);
        const fieldName = this.formControlNameList.filter((ob) => {
          return ob.formControlName === field.name;
        });
        if (fieldName.length == 0) {
          this.formControlNameList.push({
            formControlName: field.name,
            fieldValues: field,
          });
        }
      }

      if (
        this.isloggedInWith === field.loggedInWith.isam.isloggedIn &&
        field &&
        field.loggedInWith.isam.display
      ) {
        const control = this.formBuilder.control(
          field.value,
          this.bindValidations(field.validations || [])
        );
        group.addControl(field.name, control);
        const fieldName = this.formControlNameList.filter((ob) => {
          return ob.formControlName === field.name;
        });
        if (fieldName.length == 0) {
          this.formControlNameList.push({
            formControlName: field.name,
            fieldValues: field,
          });
        }
      }
    });
    this.subscriptionform = group;
    this.isCustomerAttrLoader = false;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach((valid) => {
        if (valid.name === 'required' && valid.validator) {
          validList.push(Validators.required);
        }
      });
      return Validators.compose(validList);
    }
    return null;
  }

  ngOnInit(): void {
    sessionStorage.removeItem('isIntermodal');
    this.isManager = JSON.parse(sessionStorage.getItem('isManager'));
    this.getAllNotificationTypes();
    this.formControlValueChanged();
    this.decodejwt();
  }

  get value() {
    return this.subscriptionform.value;
  }

  async getConfiguration() {
    let collection = this.dataService.collection('customAttribute');
    let query: any;
    if (this.pageType == 'Add') {
      query = [where('modeOfOperation', '==', 'addSubscription')];
    } else {
      query = [where('modeOfOperation', '==', 'editSubscription')];
    }

    const modeOfOperation = collection.find(query);
    let customEditSubscriptionDataArray : any = [];
    if (Object.keys(modeOfOperation).length !== 0) {
      (await modeOfOperation).forEach((doc) => {
        const data: any = doc.data();
        if (this.pageType == 'Add') {
          
            this.customAddSubscription.push(data);
            this.customAddSubscription.sort(
              (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
            );
            //this.customAddSubscription = doc.data();
           
          
        } else {
          // doc
          //   .data()
          //   .sort(
          //     (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
          //   );
          
          
          customEditSubscriptionDataArray.push(doc.data());
          customEditSubscriptionDataArray.sort(
            (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
          );
          this.customEditSubscriptionData = customEditSubscriptionDataArray;

          
          this.createControl(this.customEditSubscriptionData);
        }
      });
      this.createControl(this.customAddSubscription);
    }
  }

  recursiveSearch = (obj, searchKey, results = []) => {
    const r = results;
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (key === searchKey && typeof value !== 'object') {
        r.push(value);
      } else if (typeof value === 'object') {
        this.recursiveSearch(value, searchKey, r);
      }
    });
    return r;
  };

  async decodejwt() {
    const myRawToken = sessionStorage.getItem('jwtToken');
    try {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(myRawToken);
      const fetchupn = this.recursiveSearch(decodedToken, 'email');
      this.upn = fetchupn[0];
      if (this.isloggedInWith === 'isam') {
        let user: any = await this.userService.getCurrentUser();
        this.upn = user.providerData[0].uid;
      }
    } catch (err) {
      let user: any = await this.userService.getCurrentUser();
      if (this.isloggedInWith === 'isam') {
        this.upn = user.email;
      }
    }
  }

  filterByValue(value) {
    return value.filter(function (itm) {
      if (itm.restricted === false) {
        return itm;
      }
    });
  }

  getSortOrder(prop) {
    return function (a, b) {
      return a[prop].toLowerCase().localeCompare(b[prop].toLowerCase());
    };
  }

  async getAllNotificationTypesOnEdit(id) {
    let notificationTypesRequestData = {
      endpoint: '/notifications',
      method: 'get',
    };
    this.customEndpointService
      .execute('addaudiencecf', notificationTypesRequestData)
      .then(
        async (customEndPointResponse) => {
          
          this.notification = customEndPointResponse.response.data.text;
          await this.find(id);
        },
        (error) => {
          this.snackBar.openFromComponent(DataErrorSnackbarComponent, {
            duration: 6000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: { page: 'home', errorType: 'success' },
          });
        }
      );
  }
  getAllNotificationTypes() {
    let notificationTypesRequestData = {
      endpoint: '/notifications',
      method: 'get',
    };
    this.customEndpointService
      .execute('addaudiencecf', notificationTypesRequestData)
      .then(
        (customEndPointResponse) => {
          if (!this.isManager) {
            this.notification = this.filterByValue(
              customEndPointResponse.response.data.text.sort(
                this.getSortOrder('code')
              )
            );
            this.isLoader = false;
          } else {
            this.notification = customEndPointResponse.response.data.text.sort(
              this.getSortOrder('code')
            );
            this.isLoader = false;
          }
          return this.notification;
        },
        (error) => {
          this.snackBar.openFromComponent(DataErrorSnackbarComponent, {
            duration: 6000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: { page: 'home', errorType: 'success' },
          });
        }
      );
  }

  saveContinue() {
    this.submitted = true;
    if (this.pageType == 'Add') {
      this.saveAndContinueSubscription();
    } else {
      this.updateSubscription(true);
    }
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  formControlValueChanged() {
    const emailControl = this.subscriptionform.get('email');
    const phoneControl = this.subscriptionform.get('channeltypeSMStxt');
    const voiceControl = this.subscriptionform.get('channeltypeVoicetxt');

    this.subscriptionform
      .get('channeltype')
      .valueChanges.subscribe((mode: string) => {
        if (mode === 'sms') {
          phoneControl.clearValidators();
          voiceControl.clearValidators();
          emailControl.clearValidators();
          phoneControl.updateValueAndValidity();
          emailControl.updateValueAndValidity();
          voiceControl.updateValueAndValidity();
        } else if (mode === 'voice') {
          phoneControl.clearValidators();
          voiceControl.clearValidators();
          emailControl.clearValidators();
          phoneControl.updateValueAndValidity();
          emailControl.updateValueAndValidity();
          voiceControl.updateValueAndValidity();
        } else {
          phoneControl.clearValidators();
          voiceControl.clearValidators();
          emailControl.setValidators([
            Validators.required,
            Validators.email,
            Validators.pattern(
              '([a-zA-Z0-9][-a-zA-Z0-9_+.]*[a-zA-Z0-9])@([a-zA-Z0-9][-a-zA-Z0-9.]*[a-zA-Z0-9].(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}.{3}[0-9]{1,3}))'
            ),
          ]);
          phoneControl.updateValueAndValidity();
          emailControl.updateValueAndValidity();
          voiceControl.updateValueAndValidity();
        }
      });
  }

  async find(id: string) {
    let data = [];
    if (
      this.customEditSubscriptionData &&
      this.customEditSubscriptionData.length > 0
    ) {
      this.customEditSubscriptionData.sort(
        (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
      );
    }

    let getUpdatedEditConfig = this.CustomUIService.filterCustomDataById(
      id,
      this.customEditSubscriptionData,
      this.isloggedInWith
    ).subscribe((res) => {
      data.push(res);
      if (
        this.customEditSubscriptionData &&
        data.length === this.customEditSubscriptionData.length
      ) {
        data.sort(
          (a, b) => parseFloat(a.orderToShow) - parseFloat(b.orderToShow)
        );
        this.customEditSubscription = data;
        this.CustomUIService.setEditCustomAttributeWithSavedId(data);
      }
    });

    let findSubscription = {
      endpoint: `/subscription/${id}`,
      method: 'get',
    };

    this.customEndpointService
      .execute('addaudiencecf', findSubscription)
      .then( 
        (entities: any) => {
          if (!this.isValuesFilled) {
            this.NOTIFICATION_DATA = entities.response.data.text;
            let notificationCode = this.NOTIFICATION_DATA.notificationCode;
            //localStorage.setItem('editingNotificationTypeCode', notificationCode);
            this.subscriptionService.sendNotificationType(entities.response.data.text.notificationCode);
            // sessionStorage.setItem(
            //   'editingNotificationTypeCode',
            //   entities.response.data.text.notificationCode
            // );

            this.notification.forEach(async (element) => {
              if (element.code === this.NOTIFICATION_DATA.notificationCode) {           
          
                
                this.disableChannelRadioButtonsonEdit(
                  element.channels,
                  entities.response
                );
              }
            });
            this.fillValues();
          }
        },
        (error) => {
          this.snackBar.openFromComponent(DataErrorSnackbarComponent, {
            duration: 6000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: { page: 'home', errorType: 'success' },
          });
        }
      );
  }

  fillValues() {
    this.isValuesFilled = true;
    this.subscriptionform.controls['notificationtypetxt'].setValue(
      this.NOTIFICATION_DATA.notifTypeDisplayName
    );
    if (this.NOTIFICATION_DATA.channel == 'email') {
      this.channelOptions = 'email';
      this.isEmail = true;
      this.subscriptionform.controls['email'].setValue(
        this.NOTIFICATION_DATA.deliveryAddress
      );
    } else if (this.NOTIFICATION_DATA.channel == 'sms') {
      {
        this.channelOptions = 'sms';
        this.isSms = true;

        this.subscriptionform.controls['channeltypeSMStxt'].setValue(
          this.NOTIFICATION_DATA.deliveryAddress
        );
      }
    } else {
      this.channelOptions = 'voice';
      this.isVoice = true;

      this.subscriptionform.controls['channeltypeVoicetxt'].setValue(
        this.NOTIFICATION_DATA.deliveryAddress
      );
    }

    if (this.NOTIFICATION_DATA.language === 'en') {
      this.languageOptions = 'en';
      this.encheck = true;
    } else {
      this.languageOptions = 'fr';
      this.frcheck = true;
    }

    this.loading = false;

    this.editNaasOriginalInfo = {
      channel: this.NOTIFICATION_DATA.channel,
      deliveryAddress: this.NOTIFICATION_DATA.deliveryAddress,
      id: this.NOTIFICATION_DATA.id,
      language: this.NOTIFICATION_DATA.language,
      notificationTypeId: this.NOTIFICATION_DATA.notificationCode,
      subscriberId: this.upn,
    };
  }

  async updateSubscription(isSaveAndAdd) {
    this.loading = true;
    let temp = {};
    Object.assign(temp, {
      id: this.NOTIFICATION_DATA.id,
      notificationTypeId: this.subscriptionform.get('notificationtypetxt')
        .value,
    });
    Object.assign(temp, {
      subscriberId: this.upn,
    });
    if (this.isEmail) {
      this.NOTIFICATION_DATA.channel = 'email';
      this.NOTIFICATION_DATA.deliveryAddress =
        this.subscriptionform.get('email').value;
      Object.assign(temp, {
        channel: this.NOTIFICATION_DATA.channel,
        deliveryAddress: this.NOTIFICATION_DATA.deliveryAddress,
      });
    }
    if (this.isSms) {
      this.NOTIFICATION_DATA.channel = 'sms';
      const smsvalue = this.subscriptionform.get('channeltypeSMStxt').value;
      this.NOTIFICATION_DATA.deliveryAddress = smsvalue.e164Number;
      Object.assign(temp, {
        channel: this.NOTIFICATION_DATA.channel,
        deliveryAddress: this.NOTIFICATION_DATA.deliveryAddress,
      });
    }
    if (this.isVoice) {
      this.NOTIFICATION_DATA.channel = 'voice';
      const voicevalue = this.subscriptionform.get('channeltypeVoicetxt').value;
      this.NOTIFICATION_DATA.deliveryAddress = voicevalue.e164Number;
      Object.assign(temp, {
        channel: this.NOTIFICATION_DATA.channel,
        deliveryAddress: this.NOTIFICATION_DATA.deliveryAddress,
      });
    }
    const lang = this.subscriptionform.get('language').value;
    Object.assign(temp, { language: lang });
    let filterCriteriaObj;
    if (this.customEditSubscription.length > 0) {
      filterCriteriaObj = await this.filterCriteriaForEdit(
        this.customEditSubscription,
        this.subscriptionform.value
      );
    } else {
      filterCriteriaObj = {};
    }
    let data = [];
    let uiCustomelement =
      await this.CustomUIService.filterCustomUIElementForEdit(
        this.CustomUIService.getEditCustomAttributeWithSavedId(),
        this.subscriptionform.value
      ).subscribe((res) => {
        data.push(res);
      });
    let setData = await this.CustomUIService.setUIElementToSave(data);
    if (Object.keys(filterCriteriaObj).length > 0) {
      temp = Object.assign(temp, { filterCriteria: filterCriteriaObj });
    }
    if (!isSaveAndAdd) {
      if (_.isEqual(temp, this.editNaasOriginalInfo)) {
        if (this.ChangeInCustomElemements()) {
          let saveCustomUI = await this.CustomUIService.saveCustomUIElement(
            this.NOTIFICATION_DATA.id,
            this.pageType
          );
          this.openUpdatePopup();
        } else {
          if (this.customEditSubscription.length > 0) {
            this.openUpdatePopup();
          } else {
            this.submitSubscription(temp);
          }
        }
      } else {
        this.submitSubscription(temp);
      }
    } else {
      this.submitAndContinueSubscription(temp);
    }
  }

  filterCriteriaForEdit(editData, _formValue) {
    var _filterCriteria = {};
    return new Promise((resolve) => {
      editData.forEach((data, index) => {
        const formValue = _formValue[data.name];
        const formKey = data.name;
        if (this.isloggedInWith === 'aad' && data.loggedInWith.aad.isEditable) {
          if (data.filterCriteria) {
            Object.assign(_filterCriteria, { [formKey]: formValue });
          }
        }
        if (
          this.isloggedInWith === 'isam' &&
          data.loggedInWith.isam.isEditable
        ) {
          if (data.filterCriteria) {
            Object.assign(_filterCriteria, { [formKey]: formValue });
          }
        }
        if (index == editData.length - 1) {
          resolve(_filterCriteria);
        }
      });
    });
  }

  openUpdatePopup() {
    this.loading = false;
    this.snackBar.openFromComponent(SuccessSnackbarComponent, {
      duration: 6000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      data: { page: this.pageType, errorType: 'success' },
    });
    this.subscriptionService.sendSubscriptionData(true);
    this.location.back();
  }

  ChangeInCustomElemements() {
    const customData = this.CustomUIService.getUIElementToSave();
    if (customData.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async saveSubscription() {
    this.loading = true;
    let temp = {};
    if (this.isEmail) {
      Object.assign(temp, {
        channel: 'email',
        deliveryAddress: this.subscriptionform.get('email').value,
      });
    }
    if (this.isSms) {
      const smsvalue = this.subscriptionform.get('channeltypeSMStxt').value;
      Object.assign(temp, {
        channel: 'sms',
        deliveryAddress: smsvalue.e164Number,
      });
    }
    if (this.isVoice) {
      const voicevalue = this.subscriptionform.get('channeltypeVoicetxt').value;
      Object.assign(temp, {
        channel: 'voice',
        deliveryAddress: voicevalue.e164Number,
      });
    }
    
    if (
      this.selectedUser != undefined &&
      Object.keys(this.selectedUser).length > 0
    ) {

      Object.assign(temp, {
        subscriberId:
          this.selectedUser.userPrincipalName !== ''
            ? this.selectedUser.userPrincipalName
            : this.upn,
      });
    } else {
      Object.assign(temp, {
        subscriberId: this.upn,
      });
    }

    Object.assign(temp, {
      notificationTypeId: this.notificationType,
      language: this.isEnglish,
    });
    let uiCustomelement = await this.CustomUIService.filterCustomUIElement(
      this.formControlNameList,
      this.subscriptionform.value
    );
    this.uiCustomSave = await this.CustomUIService.getUIElementToSave();
    const filterCriteriaObj = await this.CustomUIService.getFilterCriteria();
    if (Object.keys(filterCriteriaObj).length > 0) {
      temp = Object.assign(temp, { filterCriteria: filterCriteriaObj });
    }
    this.submitSubscription(temp);
  }

  async submitSubscription(temp: any) {
    let ifEmpty = await this.hasNull(temp);
    if (!ifEmpty) {
      let addSubscriptionRequestData = {
        payload: temp,
        endpoint: '/subscription',
        method: 'put',
        audienceDataType: 'string',
      };
      this.customEndpointService
        .execute('addaudiencecf', addSubscriptionRequestData)
        .then(async (customEndPointResponse: any) => {
          
          if (
            customEndPointResponse.response.data.code !== 200 &&
            customEndPointResponse.response.data.code !== 201
          ) {
            throw new Error(
              customEndPointResponse.response.data.text.error_description
            );
          }
          if (this.ChangeInCustomElemements()) {
            let saveCustomUI = await this.CustomUIService.saveCustomUIElement(
              customEndPointResponse.response.data.text.id,
              this.pageType
            );
          }
          this.loading = false;
          if (this.pageType == 'Add') {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'success' },
            });
            this.subscriptionService.sendSubscriptionData(true);
            this.location.back();
          } else {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'success' },
            });
            this.subscriptionService.sendSubscriptionData(true);
            this.location.back();
          }
        })
        .catch((error) => {
          if (
            error.message !== undefined &&
            error.message.toLowerCase().includes('subscription_already_exists')
          ) {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'error' },
            });
            this.loading = false;
          } else if (
            error.message !== undefined &&
            error.message.includes('INSUFFICIENT_PERMISSIONS')
          ) {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'nopermission' },
            });
            this.loading = false;
          } else {
            this.snackBar.openFromComponent(DataErrorSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: 'home', errorType: 'success' },
            });
          }
        });
    }
  }

  get formContolrs() {
    return this.subscriptionform.controls;
  }

  onSubmit() {
    if (
      (this.filteredStates.length === 0 &&
        this.subscriptionform.get('userFilter').value != '') ||
      (this.filteredStates.length != 0 &&
        this.subscriptionform.get('userFilter').value != '' &&
        this.selectedUser == undefined)
    ) {
      this.snackBar.openFromComponent(SuccessSnackbarComponent, {
        duration: 6000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        data: { page: this.pageType, errorType: 'InvalidsubscriberId' },
      });
    } else {
      this.loading = true;
      if (this.pageType == 'Add') {
        this.saveSubscription();
      } else {
        this.updateSubscription(false);
      }
      this.submitted = true;
    }
  }

  resetForm() {
    this.isEmail = true;
    this.isSms = false;
    this.isVoice = false;
    this.isEnglish = 'en';
    this.subscriptionform.get('email').setValue('');
    this.subscriptionform.get('notificationtypetxt').setValue('');
    this.subscriptionform.get('channeltypeSMStxt').setValue(undefined);
    this.subscriptionform.get('channeltypeVoicetxt').setValue(undefined);
    this.subscriptionform.get('channeltype').setValue('email');
    this.submitted = false;
    this.loading = false;
    this.clearCustomUIFields();
  }

  cancel() {
    this.subscriptionform.reset();
    this.addEditPopup.close();
    sessionStorage.removeItem('isCompanySelected');
    //sessionStorage.removeItem('editingNotificationTypeCode');
  }

  onChangeChannel(onChange: MatRadioChange) {
    this.formControlValueChanged();
    if (
      this.subscriptionform.get('userFilter').value === undefined ||
      this.subscriptionform.get('userFilter').value === ''
    ) {
    } else {
      this.subscriptionform.controls['email'].setValue(
        this.selectedUser.userPrincipalName
          ? this.selectedUser.userPrincipalName
          : ''
      );
      this.subscriptionform.controls['channeltypeSMStxt'].setValue(
        this.selectedUser.mobilePhone ? this.selectedUser.mobilePhone : ''
      );
      this.subscriptionform.controls['channeltypeVoicetxt'].setValue(
        this.selectedUser.mobilePhone ? this.selectedUser.mobilePhone : ''
      );
    }
    if (onChange.value === 'email') {
      this.isEmail = true;
      this.isSms = false;
      this.isVoice = false;
    } else if (onChange.value === 'sms') {
      this.isSms = true;
      this.isEmail = false;
      this.isVoice = false;
    } else {
      this.isSms = false;
      this.isEmail = false;
      this.isVoice = true;
    }
  }

  onChangeLanguage(onChange: MatRadioChange) {
    this.formControlValueChanged();
    this.isEnglish = onChange.value;
  }

  hasNull(target) {
    for (var member in target) {
      if (target[member] == null || target[member] == undefined) return true;
    }
    return false;
  }

  async saveAndContinueSubscription() {
    this.loading = true;
    let temp = {};
    if (this.isEmail) {
      Object.assign(temp, {
        channel: 'email',
        deliveryAddress: this.subscriptionform.get('email').value,
      });
    } else if (this.isSms) {
      {
        const smsvalue = this.subscriptionform.get('channeltypeSMStxt').value;
        Object.assign(temp, {
          channel: 'sms',
          deliveryAddress: smsvalue.e164Number,
        });
      }
    } else if (this.isVoice) {
      const voicevalue = this.subscriptionform.get('channeltypeVoicetxt').value;
      Object.assign(temp, {
        channel: 'voice',
        deliveryAddress: voicevalue.e164Number,
      });
    }
    if (
      this.selectedUser != undefined &&
      Object.keys(this.selectedUser).length > 0
    ) {
      Object.assign(temp, {
        subscriberId:
          this.selectedUser.userPrincipalName !== ''
            ? this.selectedUser.userPrincipalName
            : this.upn,
      });
    } else {
      Object.assign(temp, {
        subscriberId: this.upn,
      });
    }
    Object.assign(temp, {
      notificationTypeId: this.notificationType,
      language: this.isEnglish,
    });
    let uiCustomelement = await this.CustomUIService.filterCustomUIElement(
      this.formControlNameList,
      this.subscriptionform.value
    );
    this.uiCustomSave = await this.CustomUIService.getUIElementToSave();
    const filterCriteriaObj = await this.CustomUIService.getFilterCriteria();
    if (Object.keys(filterCriteriaObj).length > 0) {
      temp = Object.assign(temp, { filterCriteria: filterCriteriaObj });
    }
    this.submitAndContinueSubscription(temp);
  }

  async submitAndContinueSubscription(temp) {
    let ifEmpty = await this.hasNull(temp);
    if (!ifEmpty) {
      let addSubscriptionRequestData = {
        payload: temp,
        endpoint: '/subscription',
        method: 'put',
        audienceDataType: 'string',
      };
      this.customEndpointService
        .execute('addaudiencecf', addSubscriptionRequestData)
        .then(async (customEndPointResponse: any) => {
          if (
            customEndPointResponse.response.data.code !== 200 &&
            customEndPointResponse.response.data.code !== 201
          ) {
            throw new Error(
              customEndPointResponse.response.data.text.error_description
            );
          }
          this.loading = false;

          this.subscriptionform.controls['email'].setValue(this.upn);
          this.subscriptionform.controls['channeltypeSMStxt'].setValue('');
          this.subscriptionform.controls['channeltypeVoicetxt'].setValue('');
          this.selectedUser = {};
          this.subscriptionform.get('userFilter').setValue('');

          if (this.pageType == 'Add') {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'success' },
            });
            let saveCustomUI = await this.CustomUIService.saveCustomUIElement(
              customEndPointResponse.response.data.text.id,
              this.pageType
            );
            this.subscriptionService.sendSubscriptionData(true);
            this.resetForm();
          } else {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'success' },
            });
            this.subscriptionService.sendSubscriptionData(true);
            this.resetForm();
          }
        })
        .catch((error) => {
          if (
            error.message !== undefined &&
            error.message.toLowerCase().includes('subscription_already_exists')
          ) {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'error' },
            });
            this.loading = false;
          } else if (
            error.message !== undefined &&
            error.message.includes('INSUFFICIENT_PERMISSIONS')
          ) {
            this.snackBar.openFromComponent(SuccessSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: this.pageType, errorType: 'nopermission' },
            });
            this.loading = false;
          } else {
            this.snackBar.openFromComponent(DataErrorSnackbarComponent, {
              duration: 6000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              data: { page: 'home', errorType: 'success' },
            });
          }
        });
    }
  }

  resetFields() {
    this.emaildisabled = false;
    this.smsdisabled = false;
    this.voicecalldisabled = false;
    this.endisabled = false;
    this.frdisabled = false;
    this.emailcheck = false;
    this.smscheck = false;
    this.voicecallcheck = false;
    this.encheck = false;
    this.frcheck = false;
  }

  onChange(selected) {
    this.subscriptionform.get('email').reset();
    this.subscriptionform.get('channeltypeSMStxt').reset();
    this.subscriptionform.get('channeltypeVoicetxt').reset();
    
    sessionStorage.setItem(
      'selectedNotificationType',
      JSON.stringify(selected.value.code)
    );

    this.subscriptionService.sendNotificationType(true);

    this.formControlValueChanged();
    this.resetFields();
    this.apiResponse = this.notification;
    for (let i = 0; i < this.apiResponse.length; i++) {
      const selection = this.apiResponse[i];
      this.notificationType = selected.value.code;
      if (this.notificationType === selection.code) {
        this.selectedResult = selection.channels;
        if (this.selectedResult.length === 0) {
          this.smsdisabled =
            this.emaildisabled =
            this.voicecalldisabled =
            this.endisabled =
            this.frdisabled =
              true;
          this.emailcheck = false;
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            duration: 6000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: { page: this.pageType, errorType: 'disabled' },
          });
        } else this.disableChannelRadioButtons(this.selectedResult);
      }
    }
  }

  disableChannelRadioButtons(val) {
    this.selectedResult = val;
    const channels = [];

    for (let i = 0; i < this.selectedResult.length; i++) {
      const row = this.selectedResult[i];

      channels.push(this.selectedResult[i].code);

      this.disableChannels(channels, row);
    }
  }

  disableChannelRadioButtonsonEditChannels(val) {
    this.selectedResult = val;
    const channels = [];

    for (let i = 0; i < this.selectedResult.length; i++) {
      const row = this.selectedResult[i];

      channels.push(this.selectedResult[i].code);

      this.disableOtherChannelsOnEdit(channels, row);
    }
  }

  disableOtherChannelsOnEdit(val, row) {
    const channels = val;
    this.resetFields();

    if (channels.length === 0) {
      this.snackBar.openFromComponent(SuccessSnackbarComponent, {
        duration: 6000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        data: { page: this.pageType, errorType: 'disabled' },
      });
    } else if (channels.length === 1) {
      if (channels.includes('email')) {
        this.emaildisabled = false;
        this.smsdisabled = true;
        this.voicecalldisabled = true;
        this.channelOptions = 'email';
        this.disableOtherLanguageOnEdit(row.languages);
      }
      if (channels.includes('sms')) {
        this.emaildisabled = true;
        this.smsdisabled = false;
        this.voicecalldisabled = true;
        this.channelOptions = 'sms';
        this.disableOtherLanguageOnEdit(row.languages);
      }
      if (channels.includes('voice')) {
        this.emaildisabled = true;
        this.smsdisabled = true;
        this.voicecalldisabled = false;
        this.channelOptions = 'voice';
        this.disableOtherLanguageOnEdit(row.languages);
      }
    } else if (channels.length === 2) {
      if (channels.includes('email') && channels.includes('sms')) {
        this.emaildisabled = false;
        this.smsdisabled = false;
        this.voicecalldisabled = true;
        this.channelOptions = 'email';
        this.disableOtherLanguageOnEdit(row.languages);
      }

      if (channels.includes('email') && channels.includes('voice')) {
        this.emaildisabled = false;
        this.smsdisabled = true;
        this.voicecalldisabled = false;
        this.channelOptions = 'email';
        this.disableOtherLanguageOnEdit(row.languages);
      }
      if (channels.includes('voice') && channels.includes('sms')) {
        this.emaildisabled = true;
        this.smsdisabled = false;
        this.voicecalldisabled = false;
        this.channelOptions = 'sms';
        this.disableOtherLanguageOnEdit(row.languages);
      }
    } else if (channels.length === 3) {
      if (
        channels.includes('voice') &&
        channels.includes('sms') &&
        channels.includes('email')
      ) {
        this.emaildisabled = false;
        this.smsdisabled = false;
        this.voicecalldisabled = false;
        this.disableOtherLanguageOnEdit(row.languages);
      }
    }
  }
  disableChannels(val, row) {
    const channels = val;
    this.resetFields();

    if (channels.length === 0) {
      this.snackBar.openFromComponent(SuccessSnackbarComponent, {
        duration: 6000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        data: { page: this.pageType, errorType: 'disabled' },
      });
    } else if (channels.length === 1) {
      if (channels.includes('email')) {
        this.emaildisabled = false;
        this.smsdisabled = true;
        this.voicecalldisabled = true;

        this.emailcheck = true;
        this.smscheck = false;
        this.voicecallcheck = false;

        this.isEmail = true;
        this.isSms = false;
        this.isVoice = false;

        this.channelOptions = 'email';
        this.disableLanguageRadioButtons(row.languages);
      }
      if (channels.includes('sms')) {
        this.emaildisabled = true;
        this.smsdisabled = false;
        this.voicecalldisabled = true;

        this.emailcheck = false;
        this.smscheck = true;
        this.voicecallcheck = false;

        this.isEmail = false;
        this.isSms = true;
        this.isVoice = false;

        this.channelOptions = 'sms';
        this.disableLanguageRadioButtons(row.languages);
      }
      if (channels.includes('voice')) {
        this.emaildisabled = true;
        this.smsdisabled = true;
        this.voicecalldisabled = false;

        this.emailcheck = false;
        this.smscheck = false;
        this.voicecallcheck = true;

        this.isEmail = false;
        this.isSms = false;
        this.isVoice = true;

        this.channelOptions = 'voice';
        this.disableLanguageRadioButtons(row.languages);
      }
    } else if (channels.length === 2) {
      if (channels.includes('email') && channels.includes('sms')) {
        this.emaildisabled = false;
        this.smsdisabled = false;
        this.voicecalldisabled = true;

        this.emailcheck = true;
        this.smscheck = false;
        this.voicecallcheck = false;

        this.isEmail = true;
        this.isSms = false;
        this.isVoice = false;

        this.channelOptions = 'email';
        this.disableLanguageRadioButtons(row.languages);
      }

      if (channels.includes('email') && channels.includes('voice')) {
        this.emaildisabled = false;
        this.smsdisabled = true;
        this.voicecalldisabled = false;

        this.emailcheck = true;
        this.smscheck = false;
        this.voicecallcheck = false;

        this.isEmail = true;
        this.isSms = false;
        this.isVoice = false;
        this.channelOptions = 'email';

        this.disableLanguageRadioButtons(row.languages);
      }
      if (channels.includes('voice') && channels.includes('sms')) {
        this.emaildisabled = true;
        this.smsdisabled = false;
        this.voicecalldisabled = false;

        this.emailcheck = false;
        this.smscheck = true;
        this.voicecallcheck = false;

        this.isEmail = false;
        this.isSms = true;
        this.isVoice = false;
        this.channelOptions = 'sms';
        this.disableLanguageRadioButtons(row.languages);
      }
    } else if (channels.length === 3) {
      if (
        channels.includes('voice') &&
        channels.includes('sms') &&
        channels.includes('email')
      ) {
        this.emaildisabled = false;
        this.smsdisabled = false;
        this.voicecalldisabled = false;

        this.emailcheck = true;
        this.smscheck = false;
        this.voicecallcheck = false;

        this.isEmail = true;
        this.isSms = false;
        this.isVoice = false;
        this.disableLanguageRadioButtons(row.languages);
      }
    }
  }

  disableChannelRadioButtonsonEdit(channels, entered) {
    this.resetFields();
    if (entered) {
      if (entered.language === 'en') {
        this.encheck = true;
        this.languageOptions = 'en';
      } else {
        this.frcheck = true;
        this.languageOptions = 'fr';
      }
      if (entered.channel === 'email') {
        this.emailcheck = true;
        this.smscheck = false;
        this.voicecallcheck = false;
        this.isEmail = true;
        this.isSms = false;
        this.isVoice = false;
        this.channelOptions = 'email';
      }
      if (entered.channel === 'sms') {
        this.emailcheck = false;
        this.smscheck = true;
        this.voicecallcheck = false;
        this.isEmail = false;
        this.isSms = true;
        this.isVoice = false;
        this.channelOptions = 'sms';
      }
      if (entered.channel === 'voice') {
        this.emailcheck = false;
        this.smscheck = false;
        this.voicecallcheck = true;
        this.isEmail = false;
        this.isSms = false;
        this.isVoice = true;
        this.channelOptions = 'voice';
      }
    }
    this.disableChannelRadioButtonsonEditChannels(channels);
  }

  disableOtherLanguageOnEdit(val) {
    if (val.length == 1) {
      if (val.includes('en')) {
        this.endisabled = false;
        this.frdisabled = true;
        this.encheck = true;
        this.languageOptions = 'en';
      } else if (val.includes('fr')) {
        this.endisabled = true;
        this.frdisabled = false;
        this.isEnglish = 'fr';
        this.frcheck = true;
        this.languageOptions = 'fr';
      } else {
        this.endisabled = true;
        this.frdisabled = true;
        this.encheck = false;
        this.frcheck = false;
      }
    } else if (val.length > 0) {
      if (val.includes('en') && val.includes('fr')) {
        this.endisabled = false;
        this.frdisabled = false;
        this.encheck = true;
        this.languageOptions = 'en';
        this.frcheck = false;
        return;
      }
      if (val === '') {
        this.endisabled = true;
        this.frdisabled = true;
      } else if (val === 'fr') {
        this.frdisabled = false;
        this.frcheck = true;
        this.languageOptions = 'fr';
        this.isEnglish = 'fr';
      } else {
        this.endisabled = false;
        this.encheck = true;
        this.languageOptions = 'en';
      }
    } else {
      this.endisabled = true;
      this.frdisabled = true;
    }
  }

  disableLanguageRadioButtons(val) {
    if (val.length == 1) {
      if (val.includes('en')) {
        this.endisabled = false;
        this.frdisabled = true;
        this.encheck = true;
        this.languageOptions = 'en';
      } else if (val.includes('fr')) {
        this.endisabled = true;
        this.frdisabled = false;
        this.isEnglish = 'fr';
        this.frcheck = true;
        this.languageOptions = 'fr';
      } else {
        this.endisabled = true;
        this.frdisabled = true;
        this.encheck = false;
        this.frcheck = false;
      }
    } else if (val.length > 0) {
      if (val.includes('en') && val.includes('fr')) {
        this.endisabled = false;
        this.frdisabled = false;
        this.encheck = true;
        this.languageOptions = 'en';
        this.frcheck = false;
        return;
      }
      if (val === '') {
        this.endisabled = true;
        this.frdisabled = true;
      } else if (val === 'fr') {
        this.frdisabled = false;
        this.frcheck = true;
        this.languageOptions = 'fr';
        this.isEnglish = 'fr';
      } else {
        this.endisabled = false;
        this.encheck = true;
        this.languageOptions = 'en';
      }
    } else {
      this.endisabled = true;
      this.frdisabled = true;
    }
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      id: 'alertDialog',
      data: {
        pageT: this.pageType,
      },
    });
  }

  /* Not being used anywhere
  filterUser(e) {
    this.filteredStates = [];
    let value = this.subscriptionform.get('userFilter').value;
    let collection = this.dataService.collection(
      'UserList'
    );
    let query = new Query();
    query
      .matches('surname', '^' + value)
      .or()
      .matches('userPrincipalName', '^' + value)
      .or()
      .matches('displayName', '^' + value);
    collection.find(query).subscribe(
      (entities: any) => {
        this.filteredStates = entities;
      },
      (error) => {}
    );

    if (value === undefined || value === '') {
      this.filteredStates = [];
      this.subscriptionform.controls['email'].setValue('');
      this.subscriptionform.controls['channeltypeSMStxt'].setValue('');
      this.subscriptionform.controls['channeltypeVoicetxt'].setValue('');
    }
  }*/

  onEnter(evt: any, value) {
    if (evt.source.selected) {
      this.selectedUser = value;

      this.subscriptionform.controls['email'].setValue(
        this.selectedUser.userPrincipalName
          ? this.selectedUser.userPrincipalName
          : this.upn
      );
      this.subscriptionform.controls['channeltypeSMStxt'].setValue(
        this.selectedUser.mobilePhone ? this.selectedUser.mobilePhone : ''
      );
      this.subscriptionform.controls['channeltypeVoicetxt'].setValue(
        this.selectedUser.mobilePhone ? this.selectedUser.mobilePhone : ''
      );
    }
  }

  async clearCustomUIFields() {
    const customUI = await this.CustomUIService.getUIElementToSave();
    if (customUI.length > 0) {
      customUI.forEach((item) => {
        this.subscriptionform.controls[item.formKey].setValue('');
        this.subscriptionform.controls[item.formKey].markAsUntouched();
      });
    } else {
    }
  }
}
