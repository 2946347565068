import { Routes } from '@angular/router';
import { AccessErrorComponent } from './access-error/access-error.component';
import { AuthorizationGuard } from './auth/routeAuthGuard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authLogin/authLogin.module').then((m) => m.AuthLoginModule),
  },
  {
    path: 'home',
    canActivate: [AuthorizationGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./unsubscribe/unsubscribe.module').then(
        (m) => m.UnsubscribeModule
      ),
  },
  {
    path: 'templates',
    canActivate: [AuthorizationGuard],
    loadChildren: () =>
      import('./templatemgmt/templatemgmt.module').then(
        (m) => m.TemplatemgmtModule
      ),
  },
  {
    path: 'access-error',
    canActivate: [AuthorizationGuard],
    component: AccessErrorComponent,
  },
];
