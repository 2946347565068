import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
 import { MobileCoreConfig } from "@cn/mobile-core";
//import { MobileCoreModule } from '@cn/mobile-core';
import { AppRoutingModule } from '../app/app-routing.module';
import { AppComponent } from '@src/app/app.component';
import { environment } from '@src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { SharedModule } from '@src/app/shared/shared.module';
import { CommonService } from '@src/app/services/common.service';
import { ADGroupGuard } from '@src/app/auth/authGuard';
import { AccessErrorComponent } from '@src/app/access-error/access-error.component';
import { AuthorizationGuard } from './auth/routeAuthGuard';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SubscriptionModule } from './subscription/subscription.module';
import { SubscriptionService } from './services/subscription.service';
import { UserGuard } from './auth/userGuard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader'

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent, AccessErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatGridListModule,
    MatSelectModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatRadioModule,
    NgxIntlTelInputModule,
    SharedModule,
    // MobileCoreModule.init({
    //   appKey: environment.KINVEY_APP_KEY,
    //   appSecret: environment.KINVEY_APP_SECRET,
    //   redirectUri: environment.KINVEY_redirectUri,
    //   instanceId: environment.KINVEY_INSTANCE_ID,
    //   useRedirectForLoginOnWeb: true,
    //   pathAfterRedirectLogin: 'home',
    //   useProdLogin: environment.production,
    //   micId: environment.KINVEY_MIC_ID_ISAM,
    //   apiVersion: 4,
    // }),
    SubscriptionModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatGridListModule,
    MatSelectModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    TranslateModule
  ],
  providers: [
    CommonService,
    ADGroupGuard,
    UserGuard,
    AuthorizationGuard,
    SubscriptionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
