import { Injectable } from '@angular/core';
import { DataService, where } from '@cn/mobile-core';
//import { Query } from 'mobile-core/src/lib/data-service/query';
import { async } from '@angular/core/testing';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomUIService {
  private _fieldOriginal: any = [];
  obj: any;
  customUIList: any = {};
  editSelected: any = {};
  editCustomWithId: any = [];
  private dataService;
  constructor() {
    this.customUIList = {}
    this.editSelected = {}
    this.dataService= new DataService();
  }
  _fieldArray = [];
  _formValue: any;
  _saveObject: any = [];
  _fieldArrayValues = [];
  _dataWithUpdatedForEdit: any = [];
  userLoggedInWith = '';
  customEditSubscription: any = [];
  customEditConfig: any = [];
  pushData: any = [];
  _savedDataForEdit: any = [];
  _filterCriteria = {};

  async filterCustomUIElement(fieldArray, formValue) {
    this._fieldArray = [];
    this._saveObject = [];
    this._fieldArrayValues = fieldArray;
    this._formValue = formValue;
    
    let customUI = await this.lookupUI(this._fieldArrayValues[0], 0);
    return customUI;
  }

  lookupUI(obj, i) {
    
    if (i === this._fieldArrayValues.length) {
      this.setUIElementToSave(this._saveObject);
      return this._saveObject;
    } else {
      const formValue = this._formValue[obj.formControlName] ? this._formValue[obj.formControlName] : "";
      const formKey = obj.formControlName;
      const saveCollection = obj.fieldValues.toSaveCollection;
      const customUI = this.getCustomAttirbuteList();
      let fullObject = {}
      if (customUI[formKey] && customUI[formKey].length > 0) {
        fullObject = customUI[formKey].find(x => {
          if(x[obj.fieldValues.codeToShowInDropdown]) {
            return x[obj.fieldValues.codeToShowInDropdown] === formValue
          } else if(x[obj.fieldValues.codeToShowInDropdownForIntermodal2] && x[obj.fieldValues.codeToShowInDropdownForIntermodal])
          {          
      
            return (x[obj.fieldValues.codeToShowInDropdownForIntermodal2]+x[obj.fieldValues.codeToShowInDropdownForIntermodal]) === formValue
          }
        })
      }
      this._saveObject.push({
        formKey: formKey,
        formValue: formValue,
        fullObjectKey: `${formKey}-info`,
        fullObject: fullObject,
        saveCollection: saveCollection
      })

      if (obj.fieldValues.filterCriteria) {
        this.setFilterCriteria(formKey, formValue)
      }
      i = i + 1;
      this.lookupUI(this._fieldArrayValues[i], i);

    }
  }


  async deleteCustomUIElement(naasId, loggedInWith) {

    let customAttributeCollection = this.dataService.collection(
      'customAttribute',
    );
    let custAttrquery = [where('modeOfOperation', '==', 'subscriptionList')];

    const customAttributeCollectionResult = customAttributeCollection.find(custAttrquery);
    if (Object.keys(customAttributeCollectionResult).length !== 0) {
      let data: any;
      (await customAttributeCollectionResult).forEach(async (doc) => {
        data = doc.data();

      if (loggedInWith === data.loggedInWith.aad.isloggedIn &&
        data.loggedInWith.aad.display && data.loggedInWith.aad.delete) {

        await this.deleteCustomColumns(naasId,data.toSaveCollection);

      } else if (loggedInWith === data.loggedInWith.isam.isloggedIn &&
        data.loggedInWith.isam.display && data.loggedInWith.isam.delete) {

        await this.deleteCustomColumns(naasId,data.toSaveCollection);
      }

    });
  }

  }
  async deleteCustomColumns(naasId: any, toSaveCollection: any) {

    let findASUbscriptionQuery = [where('subscriptionId', '==', naasId)];
    let subscriptionInSaveCollection: any = this.dataService.collection(toSaveCollection).find(findASUbscriptionQuery);
    if(Object.keys(subscriptionInSaveCollection).length!==0){
      let data: any;
      (await subscriptionInSaveCollection).forEach((doc) => {
        let id = doc.id;     
        this.dataService.collection(toSaveCollection).removeById(id);
      });
    } 
  }
  setUIElementToSave(obj) {
    this._fieldArray = obj;
  }
  getUIElementToSave() {
    return this._fieldArray;
  }
  setOriginalCustomElement(obj) {
    this._fieldOriginal.push(obj);
  }
  getOriginalCustomElement() {
    return this._fieldOriginal;
  }
  async saveCustomUIElement(naasId, pageType) {
    this._fieldArray.forEach(async (item) => {
      let temp;
      if (pageType === "Edit") {
        if (item.id !== '') {
          temp = {
            [item.formKey]: item.formValue,
            [item.fullObjectKey]: item.fullObject == undefined?{}:item.fullObject,
            "subscriptionId": naasId,
          }
        
      
          let locationFBQuery: any;
          locationFBQuery = [where('subscriptionId', '==', naasId)];
          const subscriptionInfo = this.dataService.collection(item.saveCollection).find(locationFBQuery);
          if (Object.keys(subscriptionInfo).length !== 0) {
            (await subscriptionInfo).forEach((doc) => {
             
              const dataId: any = doc.id;
          let savedObj = this.dataService.collection(item.saveCollection).update(dataId,temp)
        
            })}
        } else {
          temp = {
            [item.formKey]: item.formValue,
            [item.fullObjectKey]: item.fullObject == undefined?{}:item.fullObject,
            "subscriptionId": naasId
          }
          this.dataService.collection(item.saveCollection).save(temp)
        }
      }
      else {
        temp = {
          [item.formKey]: item.formValue,
          [item.fullObjectKey]: item.fullObject == undefined?{}:item.fullObject,
          "subscriptionId": naasId
        }
    
        this.dataService.collection(item.saveCollection).save(temp)
      }
      
      
    })
  }


  filterCustomUIElementForEdit(editData, formValue): Observable<any> {
   
    this._saveObject = [];
    this._formValue = formValue;
    this._dataWithUpdatedForEdit = editData;

    let updateExistingData = new Observable<any>(observer => {

      editData.forEach((data) => {
        if (this.userLoggedInWith === 'aad' && data.loggedInWith.aad.isEditable) {
          const formValue = this._formValue[data.name];
          const formKey = data.name;
          const saveCollection = data.toSaveCollection;
          let id = this.userLoggedInWith === 'aad' ? data.loggedInWith.aad.id : data.loggedInWith.isam.id;
          const isFieldUpdated = this._fieldOriginal.filter(item => {
            return item[formKey] === formValue
          });
          if (isFieldUpdated.length == 0) {
            let saveObject = {
              formKey: formKey,
              formValue: formValue,
              saveCollection: saveCollection,
              id: id
            };
            if (data.filterCriteria) {
              this.setFilterCriteria(formKey, formValue)
            }
            observer.next(saveObject);
          }
        }
        if (this.userLoggedInWith === 'isam' && data.loggedInWith.isam.isEditable) {
          const formValue = this._formValue[data.name];
          const formKey = data.name;
          const saveCollection = data.toSaveCollection;
         
          let id = this.userLoggedInWith === 'isam' ? data.loggedInWith.isam.id : data.loggedInWith.aad.id;

          const isFieldUpdated = this._fieldOriginal.filter(item => {
            return item[formKey] === formValue
          });

          const customUI = this.getCustomAttirbuteList();
          
          let fullObject = {}
          if (customUI[formKey] && customUI[formKey].length > 0) {
            fullObject = customUI[formKey].find(x => {
              if(x[data.codeToShowInDropdown]) {
              return x[data.codeToShowInDropdown] === formValue
              } else if(x[data.codeToShowInDropdownForIntermodal2] && x[data.codeToShowInDropdownForIntermodal]){
                return (x[data.codeToShowInDropdownForIntermodal2]+x[data.codeToShowInDropdownForIntermodal]) === formValue
                
              }

              
            })
          }
          if (isFieldUpdated.length == 0) {
            let saveObject = {
              formKey: formKey,
              formValue: formValue,
              saveCollection: saveCollection,
              id: id,
              fullObjectKey: `${formKey}-info`,
              fullObject: fullObject,
            };
            if (data.filterCriteria) {
              this.setFilterCriteria(formKey, formValue)
            }
            observer.next(saveObject);
          }
        }
      });
    });
    return updateExistingData;
  }


  filterCustomDataById(subscriptionId, customEditConfig, loggedInWith): Observable<any> {
    this._fieldOriginal = [];
    this._fieldArray = [];
    this._saveObject = [];
    this._savedDataForEdit = [];
    this.customEditConfig = customEditConfig;
    this.userLoggedInWith = loggedInWith;
    this.customEditSubscription = new Observable<any>(observer => {
      customEditConfig.forEach(async (configRecord) => {
        let config = configRecord
        if (this.userLoggedInWith === 'aad' && config && config.loggedInWith.aad.display) {
          let collection = this.dataService.collection(
            config.toSaveCollection);
            let query: any;
            query = [where('subscriptionId', '==', subscriptionId)];
          const subscriptionInfo = collection.find(query);
          if (Object.keys(subscriptionInfo).length !== 0) {
            (await subscriptionInfo).forEach((doc) => {
              const data: any = doc.data();
            let updatedConfigData = config;
            let updatedConfig: any;
            if (data.length > 0) {
              updatedConfig = {
                [config.name]: data[0][config.name]
              }
              updatedConfigData.loggedInWith.aad.selectedValue = data[0][config.name];
              //updatedConfigData.loggedInWith.aad['id'] = configRecord.id;
              observer.next(updatedConfigData);
            } else {
              updatedConfig = {
                [config.name]: ''
              }
              updatedConfigData.loggedInWith.aad['id'] = '';
              observer.next(updatedConfigData);
            }
            this.setOriginalCustomElement(updatedConfig);
          });
        }
        } else if (this.userLoggedInWith === 'isam' && config && config.loggedInWith.isam.display) {
          let collection = this.dataService.collection(
            config.toSaveCollection);
          let query : any;
          query = [where('subscriptionId', '==', subscriptionId)];
          const subscriptionInfo = collection.find(query);
          let subscriptionData: any = []
          if (Object.keys(subscriptionInfo).length !== 0) {
            (await subscriptionInfo).forEach((doc) => {
           
              subscriptionData.push(doc.data());
            
            let updatedConfigData = config;
            let updatedConfig: any;
            if (subscriptionData.length > 0) {
              updatedConfig = {
                [config.name]: subscriptionData[0][config.name]
              }
              updatedConfigData.loggedInWith.isam.selectedValue = subscriptionData[0][config.name];
              //updatedConfigData.loggedInWith.isam['id'] = configRecord.id;
              observer.next(updatedConfigData);
            } else {
              updatedConfig = {
                [config.name]: ''
              }
              updatedConfigData.loggedInWith.isam['id'] = '';
              observer.next(updatedConfigData);
            }
            this.setOriginalCustomElement(updatedConfig);
          });
        }
        } else {
        }
      });
    });
     return this.customEditSubscription;
  }
  setFilterCriteria(objKey, objValue) {
    if(objValue!==undefined && objValue!=='')
    Object.assign(this._filterCriteria, { [objKey]: objValue })
  }
  getFilterCriteria() {
    return this._filterCriteria;
  }

  setCustomAttirbuteList(key, obj) {
    if(obj!==undefined){
    if (!this.customUIList.hasOwnProperty(key)) {
      Object.assign(this.customUIList, { [key]: obj })
    } else {
      this.customUIList[key] = obj;
    }
  }
  }
  getCustomAttirbuteList() {
    return this.customUIList;
  }
  setEditSelected(obj, value) {
    if (!this.editSelected.hasOwnProperty(obj)) {
      Object.assign(this.editSelected, { [obj]: value })
    } else {
      this.editSelected[obj] = value
    }
  }
  getEditSelected() {
    return this.editSelected;
  }
  setEditCustomAttributeWithSavedId(obj) {
    this.editCustomWithId = [];
    this.editCustomWithId = obj;
  }
  getEditCustomAttributeWithSavedId() {
    return this.editCustomWithId
  }


  


}
