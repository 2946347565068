import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  selLang = '';
  disable: string;
  category: string;
  constructor() { }

  set selectedLang(val) {
    this.selLang = val;
  }

  get selectedLang() {
    return this.selLang;
  }

  get getIsDisabledCust() {
    if(this.disable != null) {
      return true;
    } else {
      return false;
    }
       
  }

  set setIsDisabledCust(value) {
    this.disable = value;
  }

  set setSelectCategory(val) {
    this.category = val;
  }

  get getSelectCategory() {
    return this.category;
  }

}
