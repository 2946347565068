import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html'
})
export class LoaderComponent {
    constructor(public dialog: MatDialog) {}

}
