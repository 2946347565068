import { Component, OnInit } from '@angular/core';

import { environment } from '@src/environments/environment';

import {
  MobileCore,
  MobileCoreSDKConfig,
  MobileCoreConfig,
  UserService,
} from '@cn/mobile-core';

@Component({
  selector: 'app-root',

  templateUrl: './app.component.html',

  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public mobileCoreConfig: MobileCoreSDKConfig = environment;
  public mobileCore: any;
  //private userService = new UserService();

  ngOnInit() {
    this.mobileCore = new MobileCore(this.mobileCoreConfig);
    this.mobileCore.init();
    //console.log(this.userService.getCurrentUser());
  }
}
